import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Components
import { MailchimpForm } from "../forms/form";

// Menu Data
import { UseEnglishFooterData } from "../hooks/useEnglishFooterData";
import { UseFrenchFooterData } from "../hooks/useFrenchFooterData";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  border-top: 1px solid #09184f;
  margin: 120px 0 0 0;

  & .newsletter-container {
    padding: 75px 50px 80px;

    @media (max-width: 1445px) {
      padding: 75px 40px 80px;
    }

    @media (max-width: 999px) {
      padding: 75px 30px 80px;
    }

    @media (max-width: 800px) {
      padding: 75px 25px 80px;
    }
  }

  & .links-container {
    padding: 80px 50px;

    @media (max-width: 1445px) {
      padding: 80px 40px;
    }

    @media (max-width: 999px) {
      padding: 80px 30px;
    }

    @media (max-width: 800px) {
      padding: 80px 25px;
    }
  }

  & .newsletter-container {
    width: 100%;

    & .text-container {
      max-width: 350px;
      margin: 0 auto;
    }

    & .form-container {
      & .field {
        margin: 0 auto;
      }
    }
  }

  & .links-container {
    border-top: 1px solid #09184f;
    width: 100%;

    & .inner-links-container {
      max-width: 1020px;
      margin: 0 auto;

      /* display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 100px; */

      display: flex;
      flex-direction: row;
      justify-content: center;
      grid-column-gap: 100px;

      @media (max-width: 1060px) {
        grid-column-gap: 80px;
      }

      @media (max-width: 975px) {
        grid-column-gap: 40px;
      }

      @media (max-width: 875px) {
        grid-column-gap: 20px;
      }

      @media (max-width: 767px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }

      & h5 {
        margin: 0 0 25px 0;
      }

      & ol {
        & li {
          & p {
            margin: 0;
          }
        }

        @media (max-width: 767px) {
          margin: 0 0 80px 0;
        }
      }
    }
  }

  & .footer-language-toggle {
    & .tag {
      margin: 0 0 22px 0px;
    }

    & ol {
      @media (max-width: 767px) {
        margin: 0;
      }

      & li {
        & button {
          display: grid;
          grid-template-columns: 30px auto;
          align-items: center;

          height: 30px;

          width: fit-content;

          text-decoration: underline;
          text-decoration-color: transparent;
          text-underline-offset: 0.2em;

          transition: text-decoration-color 300ms;

          &:hover {
            text-decoration-color: #09184f;
          }

          & .dot {
            width: 11px;
            height: 11px;
            border-radius: 100%;

            border: 1px solid #09184f;
          }
        }

        &:hover,
        &.active {
          & button {
            & .dot {
              background-color: #09184f;
            }
          }
        }
      }
    }
  }
`;

export const Footer = ({ currentLanguage, setCurrentLanguage }) => {
  const englishFooterData = UseEnglishFooterData();
  const frenchFooterData = UseFrenchFooterData();
  const [footerData, setFooterData] = useState(englishFooterData);

  useEffect(() => {
    if (currentLanguage === `en`) {
      setFooterData(englishFooterData);
    } else {
      setFooterData(frenchFooterData);
    }
  }, [currentLanguage]);

  if (footerData.prismicFooter === null) return null;

  const followUsLinks = footerData.prismicFooter.data.follow_us.map(
    (link, index) => (
      <li key={`single_footer_follow_us_link_${index}`}>
        <PrismicRichText field={link.link.richText} />
      </li>
    )
  );

  const aboutRinckLinks = footerData.prismicFooter.data.about_rinck
    .filter((link) => link.link.document !== null)
    .map((link, index) => (
      <li
        key={`single_footer_about_rinck_link_${index}_${link.link.document.id}`}
      >
        <Link to={link.link.document.url}>
          {link.link.document.data.title.text}
        </Link>
      </li>
    ));

  const customerServiceLinks = footerData.prismicFooter.data.customer_service
    .filter((link) => link.link.document !== null)
    .map((link, index) => (
      <li
        key={`single_footer_customer_service_link_${index}_${link.link.document.id}`}
      >
        <Link to={link.link.document.url}>
          {link.link.document.data.title.text}
        </Link>
      </li>
    ));

  return (
    <FooterWrapper>
      <div className="newsletter-container center">
        <div className="text-container">
          <div className="title">
            <h3>{footerData.prismicFooter.data.newsletter_title}</h3>
          </div>

          <div className="text">
            <PrismicRichText
              field={
                footerData.prismicFooter.data.newsletter_signup_text.richText
              }
            />
          </div>
        </div>

        <div className="form-container">
          <MailchimpForm
            color={`#09184F`}
            formName={`footer`}
            currentLanguage={currentLanguage}
            placeholder={
              currentLanguage === `en` ? `Email address` : `adresse e-mail`
            }
          />
        </div>
      </div>

      <div className="links-container">
        <div className="inner-links-container">
          <div className="column-1">
            <h5 className="tag uppercase gotham-bold">
              {footerData.prismicFooter.data.customer_service_title}
            </h5>

            <ol>{customerServiceLinks}</ol>
          </div>
          <div className="column-2">
            <h5 className="tag uppercase gotham-bold">
              {footerData.prismicFooter.data.about_rinck_title}
            </h5>

            <ol>{aboutRinckLinks}</ol>
          </div>
          <div className="column-3">
            <h5 className="tag uppercase gotham-bold">
              {footerData.prismicFooter.data.follow_us_title}
            </h5>

            <ol>{followUsLinks}</ol>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};
