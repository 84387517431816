exports.linkResolver = (doc) => {
  // return `/${doc.lang}/${doc.uid}/`;
  //     return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;

  switch (doc.type) {
    case "article": {
      // return `/${doc.lang}/articles/${doc.uid}/`;
      return doc.lang === `en-gb`
        ? `/en/articles/${doc.uid}/`
        : `/fr/articles/${doc.uid}/`;
    }

    case "our_history": {
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    case "collection": {
      // return `/${doc.lang}/collections/${doc.uid}/`;
      return doc.lang === `en-gb`
        ? `/en/collections/${doc.uid}/`
        : `/fr/collections/${doc.uid}/`;
    }

    case "homepage": {
      // return doc.lang === `en-gb` ? "/" : `/${doc.lang}/`;
      return doc.lang === `en-gb` ? "/" : `/fr/`;
    }

    case "press": {
      // return `/${doc.lang}/${doc.uid}/`;
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    case "journal": {
      // return `/${doc.lang}/${doc.uid}/`;
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    case "journal_category": {
      // return `/${doc.lang}/journal/category/${doc.uid}/`;
      return doc.lang === `en-gb`
        ? `/en/journal/category/${doc.uid}/`
        : `/fr/journal/category/${doc.uid}/`;
    }

    case "page": {
      // return `/${doc.lang}/${doc.uid}/`;
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    case "product": {
      if (
        doc.data?.shopify_product !== undefined &&
        doc.data?.shopify_product !== `undefined`
      ) {
        if (
          doc.data?.shopify_product?.handle !== null &&
          doc.data?.shopify_product?.handle !== undefined &&
          doc.data?.shopify_product?.handle !== `undefined`
        ) {
          if (doc.lang === `en-gb`) {
            return `/en/products/${doc.data.shopify_product.handle}/`;
          } else {
            return `/fr/products/${doc.data.shopify_product.handle}/`;
          }
        } else {
          return;
        }
      } else {
        return;
      }
    }

    case "project": {
      // return `/${doc.lang}/project/${doc.uid}/`;
      return doc.lang === `en-gb`
        ? `/en/project/${doc.uid}/`
        : `/fr/project/${doc.uid}/`;
    }

    case "cart": {
      // return `/${doc.lang}/${doc.uid}/`;
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    case "activity": {
      // return `/${doc.lang}/activities/${doc.uid}/`;
      return doc.lang === `en-gb`
        ? `/en/activities/${doc.uid}/`
        : `/fr/activities/${doc.uid}/`;
    }

    case "join_us": {
      // return `/${doc.lang}/${doc.uid}/`;
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    case "activity_category": {
      // return `/${doc.lang}/activities/category/${doc.uid}/`;
      return doc.lang === `en-gb`
        ? `/en/activities/category/${doc.uid}/`
        : `/fr/activities/category/${doc.uid}/`;
    }

    case "search": {
      // return `/${doc.lang}/${doc.uid}/`;
      return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    }

    // case "sitemap": {
    //   // return `/${doc.lang}/${doc.uid}/`;
    //   return doc.lang === `en-gb` ? `/en/${doc.uid}/` : `/fr/${doc.uid}/`;
    // }
  }
};
