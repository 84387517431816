import React from "react";

// Components
import { EnglishCustomOrderForm } from "../forms/english-custom-order-form";
import { FrenchCustomOrderForm } from "../forms/french-custom-order-form";

export const CustomOrderForm = ({ content, currentLanguage }) => {
  if (currentLanguage === `en`) {
    return <EnglishCustomOrderForm content={content} currentLanguage={`en`} />;
  } else {
    return <FrenchCustomOrderForm content={content} currentLanguage={`fr`} />;
  }
};
