import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { useLockBodyScroll, useMeasure } from "react-use";

// Icons
import { Logo } from "../icons/logo";

// Components
import { LanguageSwitcher } from "./language-switcher";
import SearchIndex from "../search/search-index";
import { DesktopShopLinks } from "./desktop-shop-links";
import { DesktopActivitiesLinks } from "./desktop-activities-links";
import { DesktopJournalLinks } from "./desktop-journal-links";

const Nav = styled.nav`
  & .navigation {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: ${(props) => props.bgColor};
    transition: 250ms background-color ease;

    z-index: 10;

    padding: 0 50px;

    @media (max-width: 1445px) {
      padding: 0 40px;
    }

    @media (max-width: 999px) {
      padding: 0 30px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }
  }

  & .logo-container {
    flex: 1;
    line-height: 78px;

    & a {
      display: flex;
      width: fit-content;

      margin: 0 auto;

      height: 38px;
    }
  }

  & ol.top-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    flex: 1;
    line-height: 78px;

    &.left-links {
    }

    &.right-links {
      justify-content: flex-end;
    }

    & li {
      margin: 0 30px 0 0;
      cursor: pointer;

      font-size: 16px;

      text-decoration: underline;
      text-decoration-color: transparent;
      text-underline-offset: 0.2em;
      text-decoration-thickness: 1px;

      transition: text-decoration-color 300ms;

      & > button {
        text-decoration: underline;
        text-decoration-color: transparent;
        text-underline-offset: 0.2em;
        text-decoration-thickness: 1px;
      }

      & > a,
      & > button {
        text-decoration-color: transparent;
        transition: text-decoration-color 300ms;
      }

      &:hover {
        text-decoration-color: #09184f;

        & > a,
        & > button {
          text-decoration-color: #09184f;
        }
      }

      &.current {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;
      }

      &:last-of-type {
        margin: 0;
      }

      &.language-toggle {
        margin: 0 0 0 10px;

        & button {
          font-size: 9px;
          line-height: 12px;
          border-bottom: 1px solid transparent;

          cursor: pointer;

          &:first-of-type {
            margin: 0 10px 0 0;
          }

          &:hover,
          &.active {
            border-bottom: 1px solid #09184f;
          }
        }
      }
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1;

  height: calc(${(props) => props.height}px - 78px);

  &.closed {
    transform: translateY(calc(-${(props) => props.height}px - 78px - 78px));

    // & .inner-section-links {
    //   & li {
    //     opacity: 0;
    //   }
    // }
  }

  &.open {
    transform: translateY(0);

    // & .inner-section-links {
    //   & li {
    //     opacity: 1;
    //   }
    // }
  }

  transition: 500ms transform ease;

  overflow-y: scroll;

  & .links-container {
    position: relative;
    background-color: #fff;

    //padding: 250px 50px 40px 50px;
    padding: 100px 50px 40px 50px;

    z-index: 1;
    min-height: calc(
      ${(props) => props.activeMenuHeight}px + 100px + 40px + 40px
    );

    &.active-menu-search {
      min-height: fit-content;
    }

    & .inner-section-links {
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    /* &.active-menu-activities { */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */

    /* min-height: 555px;  */

    /* & .inner-section-links {
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      } */
    /* } */
  }

  & .active > .title > p,
  & .active > .title > a {
    display: inline;
    width: fit-content;

    text-decoration: underline;
    text-decoration-color: #09184f;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;

    transition: text-decoration-color 300ms;
  }

  & .title > p,
  & a {
    display: inline;
    width: fit-content;

    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;

    transition: text-decoration-color 300ms;

    &:hover {
      text-decoration-color: #09184f;
    }
  }

  & ol {
    &.section-links-container {
      &.top {
        border-bottom: 1px solid rgba(145, 132, 15, 0.1);

        padding: 0 0 10px 0;
        margin: 0 0 15px 0;

        width: fit-content;
      }

      &.bottom {
        border-top: 1px solid rgba(145, 132, 15, 0.1);

        padding: 10px 0 0 0;
        margin: 30px 0 0 0;
      }

      grid-column: 1 / 4;
    }

    & li {
      &.single-link-section {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;

        transition: 300ms opacity ease;
        transition-delay: 350ms;

        font-size: 40px;
        line-height: 50px;

        cursor: pointer;

        & p {
          margin: 0;
        }

        & .title {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;

          position: relative;

          & p {
            grid-column: 1;
          }

          & .line {
            grid-column: 2;

            border-bottom: 1px solid #09184f;
            padding: 0 0 0 120px;

            font-size: 1px;
            line-height: 1px;
            width: 0;
            opacity: 0;

            transition: 300ms width ease, 300ms opacity ease;

            position: absolute;
            left: 40px;
            right: 40px;
          }
        }

        &.active {
          & .links {
            position: relative;
            display: block;
            z-index: 10;
          }

          & .line {
            width: calc(100% - 40px - 40px);
            opacity: 1;
          }
        }
      }

      & .links {
        display: none;

        & ol {
          position: absolute;
        }
      }

      &.section-savoir-faire {
        & .links {
          display: none;

          & ol {
            position: absolute;

            /* bottom: -50px; */
            /* min-height: 300px; */
          }
        }
      }
    }
  }
`;

const Fill = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #09184f;
  transition: 500ms opacity ease;

  z-index: 0;
  pointer-events: none;

  &.closed {
    opacity: 0;
  }

  &.open {
    opacity: 0.85;
  }
`;

export const DesktopMenu = ({
  currentLanguage,
  setCurrentLanguage,
  data,
  location,
  headerColor,
  setHeaderColor,
  prevHeaderColor,
  setPageContext,
  quantity,
  pageColor,
}) => {
  const windowHeight = use100vh();
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  // Is Menu Open
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Active Menu
  const [activeMenu, setActiveMenu] = useState(null);

  // Active Inner Menu
  const [activeInnerMenu, setActiveInnerMenu] = useState(null);

  // Search Bar
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Menu Height
  const [activeMenuHeight, setActiveMenuHeight] = useState(300);

  useEffect(() => {
    setActiveMenu(null);
    setActiveInnerMenu(null);
    setIsMenuOpen(false);
  }, [location]);

  useLockBodyScroll(activeMenu === null ? false : true);

  useEffect(() => {
    if (isMenuOpen) {
      setHeaderColor(`#fff`);
      setPageContext({ isMenuOpen: true });
    } else {
      setPageContext({ isMenuOpen: false });

      if (prevHeaderColor === undefined) {
        setHeaderColor(`#fff`);
      } else {
        if (headerColor !== prevHeaderColor) {
          setHeaderColor(prevHeaderColor);
        }
      }
    }
  }, [isMenuOpen]);

  return (
    <Nav bgColor={headerColor}>
      <div className="navigation">
        <ol className="left-links classico top-links">
          <li
            className={activeMenu === `shop` ? `current` : ``}
            onMouseEnter={() => {
              if (activeMenu !== `shop`) setActiveMenu(`shop`);
              setIsMenuOpen(true);
            }}
          >
            {data.prismicMenu.data.shop_menu_title.text}
          </li>

          <li
            className={activeMenu === `activities` ? `current` : ``}
            onMouseEnter={() => {
              if (activeMenu !== `activities`) setActiveMenu(`activities`);
              setIsMenuOpen(true);
            }}
          >
            {data.prismicMenu.data.activities_menu_title.text}
          </li>

          <li
            className={activeMenu === `journal` ? `current` : ``}
            onMouseEnter={() => {
              if (activeMenu !== `journal`) setActiveMenu(`journal`);
              setIsMenuOpen(true);
            }}
          >
            {data.prismicMenu.data.journal_menu_title.text}
          </li>
        </ol>

        <div className="logo-container">
          <Link
            to={currentLanguage === `en` ? `/` : `/${currentLanguage}/`}
            onMouseEnter={() => {
              setIsMenuOpen(false);
              setActiveMenu(null);
              setActiveInnerMenu(null);
            }}
          >
            <Logo />
          </Link>
        </div>

        <ol className="right-links classico top-links">
          <li
            onMouseEnter={() => {
              if (activeMenu !== `search`) setActiveMenu(`search`);
              setIsMenuOpen(true);
            }}
          >
            <button>
              {currentLanguage === `en` ? `Search` : `Rechercher`}
            </button>
          </li>

          <li
            onMouseEnter={() => {
              setIsMenuOpen(false);
              setActiveMenu(null);
              setActiveInnerMenu(null);
            }}
          >
            <Link
              aria-label="account"
              to={`/${currentLanguage}/account/login/`}
            >
              {currentLanguage === `en` ? `Account` : `Compte`}
            </Link>
          </li>

          <li
            onMouseEnter={() => {
              setIsMenuOpen(false);
              setActiveMenu(null);
              setActiveInnerMenu(null);
            }}
          >
            <Link aria-label="cart" to={`/${currentLanguage}/cart/`}>
              {currentLanguage === `en` ? `Cart` : `Panier`}
              {quantity > 0 && `(${quantity})`}
            </Link>
          </li>

          <li className="language-toggle">
            <ol>
              <LanguageSwitcher
                currentLanguage={currentLanguage}
                setCurrentLanguage={setCurrentLanguage}
                location={location}
              />
            </ol>
          </li>
        </ol>
      </div>

      <Menu
        height={windowHeight}
        activeMenuHeight={activeMenuHeight}
        className={activeMenu !== null ? `open` : `closed`}
      >
        <div
          className={`links-container ${
            activeMenu !== null ? `active-menu-${activeMenu}` : ``
          }`}
          onMouseLeave={() => {
            setActiveMenu(null);
            setIsMenuOpen(false);
          }}
        >
          {activeMenu === `search` && (
            <SearchIndex
              setActiveMenu={setActiveMenu}
              currentLanguage={currentLanguage}
            />
          )}

          {activeMenu === `shop` && (
            <ol className="inner-section-links">
              <DesktopShopLinks
                data={data.prismicMenu.data.body}
                activeInnerMenu={activeInnerMenu}
                setActiveInnerMenu={setActiveInnerMenu}
                setActiveMenuHeight={setActiveMenuHeight}
                currentLanguage={currentLanguage}
              />
            </ol>
          )}

          {activeMenu === `activities` && (
            <ol className="inner-section-links">
              <DesktopActivitiesLinks
                data={data.prismicMenu.data.body1}
                activeInnerMenu={activeInnerMenu}
                setActiveInnerMenu={setActiveInnerMenu}
                setActiveMenuHeight={setActiveMenuHeight}
              />
            </ol>
          )}

          {activeMenu === `journal` && (
            <>
              <ol className="section-links-container top">
                <li className="classico single-link-section">
                  <Link
                    aria-label="Journal"
                    to={`/${currentLanguage}/journal/`}
                  >
                    {currentLanguage === `en`
                      ? `All articles`
                      : `Tous les articles`}
                  </Link>
                </li>
              </ol>
              <ol className="section-links-container">
                <DesktopJournalLinks
                  data={data.prismicMenu.data.body2}
                  activeInnerMenu={activeInnerMenu}
                  setActiveInnerMenu={setActiveInnerMenu}
                  setActiveMenuHeight={setActiveMenuHeight}
                />
              </ol>

              {data.prismicMenu.data.featured_edits_link.document !== null && (
                <ol className="section-links-container bottom">
                  <li className="classico single-link-section">
                    <Link
                      aria-label={
                        data.prismicMenu.data.featured_edits_link.document.data
                          .title.text
                      }
                      to={
                        data.prismicMenu.data.featured_edits_link.document.url
                      }
                    >
                      {
                        data.prismicMenu.data.featured_edits_link.document.data
                          .title.text
                      }
                    </Link>
                  </li>
                </ol>
              )}
            </>
          )}
        </div>
      </Menu>

      <Fill className={activeMenu !== null ? `open` : `closed`} />
    </Nav>
  );
};
