import React from "react";
import styled from "styled-components";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

// Utils
// import { ImageOrientation } from "../utils/image-orientation";

const EditorialImagesContainer = styled.div`
  align-items: flex-end;

  & .image-left {
    grid-column: 2 / 7;

    @media (max-width: 1445px) {
      grid-column: 1 / 7;
    }

    @media (max-width: 800px) {
      grid-column: span 6;

      margin: 0 0 80px 0;
    }
  }

  & .image-right {
    grid-column: 8 / 12;

    @media (max-width: 999px) {
      grid-column: 9 / 13;
    }

    @media (max-width: 800px) {
      grid-column: span 6;
      padding: 0 25px;
    }
  }

  & img:not([aria-hidden="true"]) {
    &.background {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border: 39px solid #efefef;
      box-sizing: border-box;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }
  }
`;

export const EditorialImages = ({ content, index }) => {
  return (
    <EditorialImagesContainer className="module editorial-images grid-12">
      <div className="image-container image-left">
        {content.primary.editorial_image_left.fluid !== null && (
          <AspectRatioImageContainer
            image={content.primary.editorial_image_left}
          >
            <GatsbyImage
              imgClassName={
                content.primary.image_left_background ? `background` : ``
              }
              image={content.primary.editorial_image_left.gatsbyImageData}
              // src={content.primary.editorial_image_left.fluid.src}
              alt={
                content.primary.editorial_image_left.alt !== null
                  ? content.primary.editorial_image_left.alt
                  : ``
              }
              fetchpriority={index <= 1 ? `high` : ``}
              loading={index <= 1 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>
        )}

        {content.primary.editorial_image_left.alt !== null && (
          <p className="caption uppercase">
            {content.primary.editorial_image_left.alt}
          </p>
        )}
      </div>

      <div className="image-container image-right">
        {content.primary.editorial_image_right.fluid !== null && (
          <AspectRatioImageContainer
            image={content.primary.editorial_image_right}
          >
            <GatsbyImage
              imgClassName={
                content.primary.image_right_background ? `background` : ``
              }
              image={content.primary.editorial_image_right.gatsbyImageData}
              // src={content.primary.editorial_image_right.fluid.src}
              alt={
                content.primary.editorial_image_right.alt !== null
                  ? content.primary.editorial_image_right.alt
                  : ``
              }
              fetchpriority={index <= 1 ? `high` : ``}
              loading={index <= 1 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>
        )}

        {content.primary.editorial_image_right.alt !== null && (
          <p className="caption uppercase">
            {content.primary.editorial_image_right.alt}
          </p>
        )}
      </div>
    </EditorialImagesContainer>
  );
};
