import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Utils
import kebabCase from "lodash.kebabcase";

// Components
import { DetermineModule } from "../components/utils/determine-module";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";
import { PageContext } from "../components/context/page-context";

const Page = styled.div`
  &.page {
    @media (max-width: 999px) {
      & .module {
        margin: 180px 0 0 0;
      }
    }

    @media (max-width: 800px) {
      & .module {
        margin: 150px 0 0 0;
      }
    }

    @media (max-width: 600px) {
      & .module {
        margin: 100px 0 0 0;
      }
    }

    &.page-type-featured-edits {
      margin: 79px 0 0 0;

      @media (max-width: 999px) {
        & .module {
          margin: 79px 0 0 0;
        }
      }

      @media (max-width: 800px) {
        & .module {
          margin: 79px 0 0 0;

          &:first-of-type {
            padding: 25px 0 100px 0 !important;
          }
        }
      }
    }
  }
`;

const PageTemplate = ({ data }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [pageContext, setPageContext] = useContext(PageContext);

  useEffect(() => {
    if (data.prismicPage.data.page_background_color !== null) {
      setPageColor(data.prismicPage.data.page_background_color);
      setHeaderColor(data.prismicPage.data.page_background_color);
    } else {
      setPageColor(`#fff`);
      setHeaderColor(`#fff`);
    }
  }, []);

  useEffect(() => {
    if (pageContext.isMenuOpen === true) {
      setHeaderColor(`#fff`);
    } else {
      if (headerColor === `#fff`) {
        if (data.prismicPage.data.page_background_color !== null) {
          setHeaderColor(data.prismicPage.data.page_background_color);
        } else {
          setHeaderColor(`#fff`);
        }
      }
    }
  }, [pageContext]);

  const activityLinkedSections = data.prismicPage.data.body
    .filter((section) => section.slice_type === "linked_section")
    .map((section, index) => (
      <li
        className="linked-section-link"
        key={`single_linked_section_${index}_${data.prismicPage.id}`}
      >
        <h3>
          <span className="line" />
          <button
            onClick={() => {
              if (typeof window !== `undefined`) {
                let dims = document
                  .querySelector(
                    `#linked-section-${kebabCase(
                      section.primary.link_title.text
                    )}`
                  )
                  .getBoundingClientRect();
                window.scrollTo({ top: dims.top - 100, behavior: "smooth" });
              }
            }}
          >
            {section.primary.link_title.text}
          </button>
        </h3>
      </li>
    ));

  const content = data.prismicPage.data.body.map((content, index) => (
    <DetermineModule
      content={content}
      index={index}
      key={`single_module_${index}_${data.prismicPage.id}`}
      pageType={`page`}
      currentLanguage={currentLanguage}
      activityLinkedSections={activityLinkedSections}
    />
  ));

  return (
    <>
      <PageSeo
        title={data.prismicPage.data.title.text}
        image={null}
        description={null}
        url={data.prismicPage.url}
      />
      <Page
        className={`page page-type-${kebabCase(
          data.prismicPage.data.title.text
        )}`}
      >
        {content}
      </Page>
    </>
  );
};

export default withPrismicPreview(PageTemplate);

export const query = graphql`
  query PageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          text
        }
        page_background_color
        body {
          ... on PrismicPageDataBodyLinkedSectionCover {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
              activity_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData
                fluid {
                  aspectRatio
                  srcSet
                  src
                }
              }
            }
          }
          ... on PrismicPageDataBodyLinkedSection {
            id
            slice_type
            primary {
              link_title {
                richText
                text
              }
              link_text {
                richText
              }
              link_image {
                alt
                fluid {
                  aspectRatio
                  src
                  srcSet
                }
                gatsbyImageData
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicPageDataBodyBigPush {
            id
            slice_type
            primary {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                    }
                  }
                }
              }
              big_push_title {
                richText
              }
              call_to_action {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyCover {
            id
            slice_type
            primary {
              cover_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
              cover_text {
                richText
              }
              cover_title {
                richText
              }
              cover_call_to_action {
                richText
              }
              image_position
              link {
                url
              }
            }
          }
          ... on PrismicPageDataBodyEdit {
            id
            slice_type
            primary {
              background_color
              journal_edit {
                type
                document {
                  ... on PrismicJournalEdit {
                    id
                    data {
                      title {
                        richText
                      }
                      articles {
                        article {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicArticle {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicJournalCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicActivity {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicProject {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyEditorialCover {
            id
            slice_type
            primary {
              editorial_credits {
                richText
              }
              editorial_text {
                richText
              }
              editorial_title {
                richText
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              image_position
            }
          }
          ... on PrismicPageDataBodyEditorialImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicPageDataBodyEditorialImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyEditorialImages {
            id
            slice_type
            primary {
              editorial_image_left {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              editorial_image_right {
                dimensions {
                  height
                  width
                }
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              image_left_background
              image_right_background
            }
          }
          ... on PrismicPageDataBodyVideo {
            id
            slice_type
            primary {
              video {
                type
                html
                embed_url
                provider_name
                id
              }
              video_size
            }
          }
          ... on PrismicPageDataBodyEditorialNote {
            id
            slice_type
            primary {
              editorial_note_title
              editorial_note_text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyEditorialQuote {
            id
            slice_type
            primary {
              editorial_quote {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyEditorialRow {
            id
            slice_type
            primary {
              editorial_row_title {
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 800)
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyEditorialText {
            id
            slice_type
            primary {
              editorial_text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyProductRowCarousel {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicPageDataBodyProductRow {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicPageDataBodyProductCardLarge {
            id
            slice_type
            primary {
              orientation
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
              }
              orientation
            }
          }
          ... on PrismicPageDataBodyEditorialIntroduction {
            id
            slice_type
            primary {
              editorial_introduction_text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyPushStory {
            id
            slice_type
            primary {
              background_color
              story {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      thumbnail {
                        alt
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        gatsbyImageData
                        dimensions {
                          height
                          width
                        }
                      }
                      excerpt_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTitleAndText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyNewsletter {
            id
            slice_type
            primary {
              newsletter {
                document {
                  ... on PrismicNewsletterSignup {
                    id
                    data {
                      background_color
                      email_placeholder
                      newsletter_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyActivities {
            id
            slice_type
            primary {
              activity_title {
                richText
              }
              activity_text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyCustomOrderForm {
            id
            slice_type
            items {
              textarea
              form_input_field
              required
            }
          }
          ... on PrismicPageDataBodyLegalNoticeAddress {
            id
            slice_type
            primary {
              text_column_left {
                richText
              }
              text_column_right {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyRefundForm {
            id
            slice_type
            primary {
              signature_text
              date_text
              delete_text
            }
            items {
              form_input_field
            }
          }
          ... on PrismicPageDataBodyCollections {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
            items {
              image {
                alt
                fluid {
                  srcSet
                  src
                }
                gatsbyImageData
              }
              name {
                richText
              }
              text {
                richText
              }
              collection {
                document {
                  ... on PrismicCollection {
                    id
                    url
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTeams {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
            items {
              image {
                alt
                fluid {
                  srcSet
                  src
                }
                gatsbyImageData
              }
              text {
                richText
              }
              name {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
