import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const EditorialNoteContainer = styled.div`
  & .inner-text-container {
    max-width: 1615px;
    margin: 0 auto;

    & .text-container {
      margin: 30px 0 0 0;

      & p {
        font-size: 30px;
        line-height: 40px;

        @media (max-width: 999px) {
          font-size: 19px;
          line-height: 26px;
        }

        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const EditorialNote = ({ title, text }) => {
  return (
    <EditorialNoteContainer className="module editorial-note">
      <div className="inner-text-container">
        <div className="title-container">
          <h2 className="center tag uppercase gotham-bold">{title}</h2>
        </div>

        <div className="text-container center classico">
          <PrismicRichText field={text.richText} />
        </div>
      </div>
    </EditorialNoteContainer>
  );
};
