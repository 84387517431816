import React from "react";

// Components
import { EnglishRefundForm } from "../forms/english-refund-form";
import { FrenchRefundForm } from "../forms/french-refund-form";

export const RefundForm = ({ content, currentLanguage }) => {
  if (currentLanguage === `en`) {
    return <EnglishRefundForm content={content} currentLanguage={`en`} />;
  } else {
    return <FrenchRefundForm content={content} currentLanguage={`fr`} />;
  }
};
