import React from "react";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";

const TitleAndTextContainer = styled.div`
  width: 100%;

  &.module {
    margin: 230px 0 150px 0;

    @media (max-width: 800px) {
      margin: 120px 0 35px 0;
    }
  }

  & .text-container {
    max-width: 880px;

    & .title {
      & h1 {
        font-size: 70px;
        line-height: 70px;

        @media (max-width: 999px) {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }

    & .text {
      margin: 23px 0 0 0;
    }
  }
`;

export const TitleAndText = ({ content }) => {
  return (
    <TitleAndTextContainer className="module title-and-text">
      <div className="text-container">
        <div className="title">
          <PrismicRichText field={content.primary.section_title.richText} />
        </div>

        <div className="text">
          <PrismicRichText field={content.primary.section_text.richText} />
        </div>
      </div>
    </TitleAndTextContainer>
  );
};
