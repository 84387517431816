import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const UseFrenchFooterData = () => {
  const frenchFooterData = useStaticQuery(graphql`
    query French {
      prismicFooter(lang: { eq: "fr-fr" }) {
        _previewable
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        id
        data {
          newsletter_signup_text {
            richText
          }
          follow_us {
            link {
              richText
            }
          }
          about_rinck_title
          about_rinck {
            link {
              document {
                ... on PrismicJoinUs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPress {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicActivityCategory {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
              }
            }
          }
          customer_service_title
          customer_service {
            link {
              document {
                ... on PrismicJoinUs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicCustomOrder {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicTermsAndConditions {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicRefund {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPress {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicActivityCategory {
                  id
                  data {
                    title {
                      text
                    }
                  }
                  url
                }
              }
            }
          }
          follow_us_title
          language_title
          newsletter_title
        }
      }
    }
  `);

  return frenchFooterData;
};
