import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const EditorialIntroductionContainer = styled.div`
  & .text-container {
    & p {
      font-size: 30px;
      line-height: 40px;

      text-align: justify;

      @media (max-width: 999px) {
        font-size: 19px;
        line-height: 26px;
      }
    }
  }
`;

export const EditorialIntroduction = ({ text }) => {
  return (
    <EditorialIntroductionContainer className="module editorial-introduction">
      <div className="text-container classico">
        <PrismicRichText field={text.richText} />
      </div>
    </EditorialIntroductionContainer>
  );
};
