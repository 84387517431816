import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const EditorialQuoteContainer = styled.div`
  & h3 {
    /* font-size: 130px; */
    /* line-height: 120px; */

    font-size: 70px;
    line-height: 70px;

    @media (max-width: 999px) {
      font-size: 60px;
      line-height: 65px;
    }

    /* @media (max-width: 800px) {
      font-size: 70px;
      line-height: 70px;
    } */
  }
`;

export const EditorialQuote = ({ content }) => {
  return (
    <EditorialQuoteContainer className="module editorial-quote">
      <PrismicRichText field={content.primary.editorial_quote.richText} />
    </EditorialQuoteContainer>
  );
};
