import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const EditorialTextContainer = styled.div`
  & .text-container {
    grid-column: 1 / 13;
    /* grid-column: 7 / 13; */

    /* @media (max-width: 1445px) {
      grid-column: 6 / 13;
    }

    @media (max-width: 999px) {
      grid-column: 5 / 13;
    } */

    @media (max-width: 800px) {
      grid-column: span 6;
    }

    & .text-wrapper {
      max-width: 780px;
      margin: 0 auto;

      & p {
        font-size: 18px;
        line-height: 30px;

        @media (max-width: 999px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const EditorialText = ({ content }) => {
  return (
    <EditorialTextContainer className="module editorial-text grid-12">
      <div className="text-container">
        <div className="text-wrapper">
          <PrismicRichText field={content.primary.editorial_text.richText} />
        </div>{" "}
      </div>
    </EditorialTextContainer>
  );
};
