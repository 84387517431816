import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

form[type="hidden"]{
  display: none;
}

body {
  font-family: "GothamSSm-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 14px;
  line-height: 24px;

  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, .004); */
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #09184F;
  margin: 0;

  transition: 250ms opacity ease, 250ms background-color ease;

  overflow-x: hidden;
  scroll-behavior: smooth;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "URWClassicoW01-Regular", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}

.classico {
  font-family: "URWClassicoW01-Regular", "Lucida Grande", sans-serif;
}

.gotham-light {
  font-family: "GothamSSm-Light", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.gotham-bold {
  font-family: "GothamSSm-Bold", "Helvetica Neue", "Lucida Grande", sans-serif;
}


h1{
  font-size: 60px;
  line-height: 65px;

  @media (max-width: 999px) {
    font-size: 40px;
    line-height: 50px;
  }
}

h2 {
  font-size: 40px;
  line-height: 50px;
}

h3 {
  font-size: 30px;
  line-height: 40px;

  // @media (max-width: 999px) {
  //   font-size: 19px;
  //   line-height: 26px;
  // }
}

h4 {
  font-size: 19px;
  line-height: 26px;
}

.small {
  font-size: 12px;
  line-height: 24px;
}

.tag {
  font-size: 11px;
  line-height: 12px;
}

.uppercase {
  text-transform:uppercase;
}

.center {
  text-align:center;
}

strong {
  font-style: normal;
  font-weight: normal;
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

.gatsby-image-wrapper{
  width: 100%;
  height:100%;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    border:0;
    margin:0;
    padding:0;

    color:inherit;
    cursor:pointer;

    background-color: transparent;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

.call-to-action,
p {
  & a {
    display: inline;
    width: fit-content;

    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0.2em;

    transition: text-decoration-color 300ms;

    &:hover {
      text-decoration-color: #09184f;
    }
  }
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

input {
  &:focus{
    outline: none;
  }
}


/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;
}

.ReactModalPortal-gallery{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;
  background-color: #fff;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;
}
`;

export default GlobalStyle;
