import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const CoverContainer = styled.div`
  &.image-layout-left {
    & .text-container {
      grid-column: 8 / 13;
      order: 2;
    }
    & .image-container {
      grid-column: 1 / 8;
      order: 1;
    }

    @media (max-width: 1445px) {
      & .text-container {
        grid-column: 8 / 13;
      }

      & .image-container {
        grid-column: 1 / 8;
      }
    }

    @media (max-width: 800px) {
      & .text-container,
      & .image-container {
        grid-column: 1 / 7;
      }
    }
  }

  &.image-layout-right {
    & .text-container {
      grid-column: 1 / 5;
      order: 1;
    }
    & .image-container {
      grid-column: 5 / 13;
      order: 2;
    }

    @media (max-width: 1445px) {
      & .text-container {
        grid-column: 1 / 6;
      }

      & .image-container {
        grid-column: 6 / 13;
      }
    }

    @media (max-width: 800px) {
      & .image-container {
        order: 1;
      }

      & .text-container {
        order: 2;
      }

      & .text-container,
      & .image-container {
        grid-column: 1 / 7;
      }
    }
  }

  & .text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-content: center;

    & .inner-text-container {
      top: 130px;
      position: sticky;

      max-width: 500px;

      & .title,
      & .text,
      & .call-to-action {
        width: 100%;
      }

      & .text {
        margin: 20px 0px 0 0;

        & p {
          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & .call-to-action {
        margin: 28px 0 0 0;

        // & a {
        //   display: block;
        //   width: fit-content;

        //   text-decoration: underline;
        //   text-decoration-color: transparent;
        //   text-underline-offset: 0.2em;

        //   transition: text-decoration-color 300ms;

        //   &:hover {
        //     text-decoration-color: #09184f;
        //   }
        // }

        & p {
          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: 800px) {
        margin: 60px 0 0 0;

        top: 0;
        position: relative;
      }
    }
  }

  & .image-container {
    & img:not([aria-hidden="true"]) {
      border: 39px solid #efefef;
      box-sizing: border-box;

      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }
  }

  @media (max-width: 999px) {
  }

  @media (max-width: 800px) {
    padding: 0 !important;

    & .text-container {
      order: 2;

      padding: 0 25px;
    }

    & .image-container {
      order: 1;
    }
  }
`;

export const Cover = ({
  coverTitle,
  coverText,
  coverImage,
  coverCallToAction,
  coverLink,
  imagePosition,
  index,
}) => {
  return (
    <CoverContainer
      className={`module cover grid-12 image-layout-${imagePosition.toLowerCase()}`}
    >
      <div className="text-container">
        <div className="inner-text-container">
          <div className="title">
            <PrismicRichText
              field={coverTitle}
              components={{
                heading2: ({ children, index }) => (
                  <h1 className="activity-title">{children}</h1>
                ),
              }}
            />
          </div>

          <div className="text">
            <PrismicRichText field={coverText} />
          </div>

          <div className="call-to-action small uppercase">
            <Link to={coverLink}>
              <PrismicRichText field={coverCallToAction} />
            </Link>
          </div>
        </div>
      </div>

      <div className="image-container">
        {coverImage.fluid !== null && (
          <AspectRatioImageContainer image={coverImage}>
            <Link to={coverLink}>
              <GatsbyImage
                image={coverImage.gatsbyImageData}
                alt={coverImage.alt !== null ? coverImage.alt : ``}
                fetchpriority={index <= 1 ? `high` : ``}
                loading={index <= 1 ? `eager` : `lazy`}
              />
            </Link>
          </AspectRatioImageContainer>
        )}
      </div>
    </CoverContainer>
  );
};
