import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const UseSearchData = () => {
  const [searchIndex, setSearchIndex] = useState(null);
  const [searchStore, setSearchStore] = useState(null);
  const [data, setData] = useState(null);

  const searchData = useStaticQuery(graphql`
    {
      localSearchContent {
        publicIndexURL
        publicStoreURL
      }
    }
  `);

  useEffect(() => {
    // GET the index
    fetch(searchData.localSearchContent.publicIndexURL)
      .then((response) => response.json())
      .then((data) => {
        setSearchIndex(data);
      });

    // GET the content
    fetch(searchData.localSearchContent.publicStoreURL)
      .then((response) => response.json())
      .then((data) => {
        setSearchStore(data);
      });
  }, [searchData]);

  useEffect(() => {
    if (searchStore !== null && searchIndex !== null) {
      // Set the data
      setData({
        index: JSON.stringify(searchIndex),
        store: searchStore,
      });
    }
  }, [searchIndex, searchStore]);

  if (data !== null) {
    return data;
  } else {
    return null;
  }
};
