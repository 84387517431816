import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";
import kebabCase from "lodash.kebabcase";
import { useMeasure } from "react-use";

export const DesktopActivitiesLinks = ({
  data,
  activeInnerMenu,
  setActiveInnerMenu,
  setActiveMenuHeight,
}) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  setActiveMenuHeight(height + (activeInnerMenu + 1) * 50);

  const activitiesLinks = data.map((link, index) => {
    const sectionLinks = link.items
      .filter((innerLink) => innerLink.link.document !== null)
      .map((innerLink, innerIndex) => (
        <li key={`single_link_${index}_${innerLink.link.document.id}`}>
          <Link to={innerLink.link.url}>
            {innerLink.link.document.data.title.text}
          </Link>
        </li>
      ));

    return (
      <li
        key={`single_activities_section_${index}`}
        className={`classico single-link-section ${
          activeInnerMenu === index ? `active` : ``
        } section-${kebabCase(link.primary.title.text)}`}
      >
        <div className="title">
          <Link
            to={`${link.primary.page.url}`}
            onMouseEnter={() => {
              if (activeInnerMenu !== index) setActiveInnerMenu(index);
            }}
          >
            {link.primary.title.text}
          </Link>

          {sectionLinks.length >= 1 && <div className="line" />}
        </div>

        <div className={`links`}>
          {activeInnerMenu === index && <ol ref={ref}>{sectionLinks}</ol>}
        </div>
      </li>
    );
  });

  return activitiesLinks;
};
