import React from "react";
import { Link } from "gatsby";
import { useMeasure } from "react-use";

export const DesktopShopLinks = ({
  data,
  activeInnerMenu,
  setActiveInnerMenu,
  setActiveMenuHeight,
  currentLanguage,
}) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();
  setActiveMenuHeight(height + activeInnerMenu * 50);

  // Section Links
  const shopLinks = data.map((link, index) => {
    if (link.slice_type === "menu_item_with_subpages") {
      const sectionLinks = link.items
        .filter((innerLink) => innerLink.link.document !== null)
        .map((innerLink, innerIndex) => (
          <li key={`single_link_${index}_${innerLink.link.document.id}`}>
            <Link to={innerLink.link.url}>
              {innerLink.link.document.data.title.text}
            </Link>
          </li>
        ));

      return (
        <li
          key={`single_shop_section_${index}`}
          className={`classico single-link-section ${
            activeInnerMenu === index ? `active` : ``
          }`}
        >
          <div className="title">
            <Link
              to={`${link.primary.page.url}`}
              onMouseEnter={() => {
                if (activeInnerMenu !== index) setActiveInnerMenu(index);
              }}
            >
              {link.primary.title.text}
            </Link>

            {sectionLinks.length >= 1 && <div className="line" />}
          </div>

          <div className={`links`}>
            {activeInnerMenu === index && (
              <ol ref={ref}>
                {sectionLinks}{" "}
                {link.primary.title.text === `Collections` && (
                  <li>
                    <Link to={`/${currentLanguage}/collections/`}>
                      {currentLanguage === `en` ? `See More` : `Voir Plus`}
                    </Link>
                  </li>
                )}
              </ol>
            )}
          </div>
        </li>
      );
    } else {
      if (
        link.primary.page.document !== undefined &&
        link.primary.page.document !== null
      ) {
        return (
          <li
            key={`single_shop_section_${index}`}
            className={`classico single-link-section`}
          >
            <Link to={link.primary.page.url}>
              <p
                onMouseEnter={() => {
                  if (activeInnerMenu !== null) setActiveInnerMenu(null);
                }}
              >
                {link.primary.page.document.data.title.text}
              </p>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    }
  });

  return shopLinks;
};
