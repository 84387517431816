import React, { useEffect } from "react";
import { navigate } from "gatsby";

// Components
// import { linkResolver } from "../link-resolver/linkResolver";

export const LanguageSwitcher = ({
  currentLanguage,
  setCurrentLanguage,
  location,
}) => {
  useEffect(() => {
    if (location.pathname.includes(`/fr/`) && currentLanguage !== `fr`) {
      setCurrentLanguage(`fr`);
    }
  }, [location]);

  const handleNavigation = (language) => {
    if (language === `en`) {
      if (location.pathname === `/fr/`) {
        const newPathname = location.pathname.replace("/fr/", "/");
        navigate(newPathname);
      } else {
        const newPathname = location.pathname.replace("fr", "en");
        navigate(newPathname);
      }
    } else {
      if (location.pathname === `/`) {
        const newPathname = location.pathname.replace("/", "/fr/");
        navigate(newPathname);
      } else {
        const newPathname = location.pathname.replace("en", "fr");
        navigate(newPathname);
      }
    }
  };

  return (
    <li className="language-switcher">
      <button
        className={`en-toggle gotham-bold ${
          currentLanguage === `en` ? `active` : ``
        }`}
        onClick={() => {
          setCurrentLanguage(`en`);
          handleNavigation(`en`);
        }}
      >
        EN
      </button>
      <button
        className={`fr-toggle gotham-bold ${
          currentLanguage === `fr` ? `active` : ``
        }`}
        onClick={() => {
          setCurrentLanguage(`fr`);
          handleNavigation(`fr`);
        }}
      >
        FR
      </button>
    </li>
  );
};
