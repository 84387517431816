import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";

const SearchContainer = styled.div`
  width: 100%;
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  & .input-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    border-bottom: 1px solid #09184f;

    & input {
      padding: 0 0 5px 0;
      height: auto;

      border: 0;

      width: calc(100% - 100px);
      color: #09184f;

      background-color: transparent;

      font-size: 40px;
      line-height: 50px;

      &:focus {
        outline: none;
      }

      ::-webkit-input-placeholder {
        font-size: 40px;
        line-height: 50px;
      }

      ::-moz-placeholder {
        font-size: 40px;
        line-height: 50px;
      }

      :-ms-input-placeholder {
        font-size: 40px;
        line-height: 50px;
      }

      :-moz-placeholder {
        font-size: 40px;
        line-height: 50px;
      }

      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;

        ::-webkit-input-placeholder {
          font-size: 30px;
          line-height: 40px;
        }

        ::-moz-placeholder {
          font-size: 30px;
          line-height: 40px;
        }

        :-ms-input-placeholder {
          font-size: 30px;
          line-height: 40px;
        }

        :-moz-placeholder {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }

    & button[type="submit"] {
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;

      padding: 5px 0;
    }
  }

  & .mobile-submit-button {
    display: none;
  }

  @media (max-width: 767px) {
    max-width: 100%;

    & .input-container {
      margin: 0 0 90px 0;
    }

    & input {
      order: 12;

      border-bottom: 1px solid #163262;
      color: #163262;

      height: auto;
      line-height: 20px;
    }

    & button,
    & button[type="submit"] {
      margin: 0;
      padding: 0;

      order: 1;
      align-items: center;

      letter-spacing: 0.05em;
      text-transform: uppercase;

      &.desktop-submit-button {
        display: none;
      }
    }

    & .mobile-submit-button {
      display: block;
    }
  }
`;

export const Search = ({ index, store, setActiveMenu, currentLanguage }) => {
  const [query, setQuery] = useState("");
  const results = useFlexSearch(query, index, store);

  const navigateToSearchResultsPage = (event) => {
    event.preventDefault();
    setActiveMenu(null);

    navigate(`/search/?q=${query}`, {
      state: {
        searchResults: results,
        searchQuery: query,
      },
    });
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      document.getElementById("search-input").focus();
    }
  }, []);

  return (
    <SearchContainer>
      <SearchForm onSubmit={navigateToSearchResultsPage}>
        <div className="input-container">
          <input
            id="search-input"
            name="query"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={
              currentLanguage === `en` ? `Type your seach here` : `Rechercher`
            }
            className="classico"
          />

          <button type="submit" className="desktop-submit-button">
            {currentLanguage === `en` ? `Search` : `Rechercher`}
          </button>
        </div>

        <button type="submit" className="mobile-submit-button">
          {currentLanguage === `en` ? `Search` : `Rechercher`}
        </button>
      </SearchForm>
    </SearchContainer>
  );
};
