import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Modules
import { EditorialNote } from "../modules/editorial-note";
import { ProductCarousel } from "../images/product-carousel";
import { PushStory } from "../modules/push-story";
import { Newsletter } from "../modules/newsletter";
import { EditorialCover } from "../modules/editorial-cover";
import { BigPush } from "../modules/big-push";
import { Cover } from "../modules/cover";
import { Edit } from "../modules/edit";
import { EditorialImage } from "../modules/editorial-image";
import { EditorialImageWithText } from "../modules/editorial-image-with-text";
import { EditorialImages } from "../modules/editorial-images";
import { EditorialIntroduction } from "../modules/editorial-introduction";
import { EditorialQuote } from "../modules/editorial-quote";
import { EditorialRow } from "../modules/editorial-row";
import { EditorialText } from "../modules/editorial-text";
import { ProductCard } from "../modules/product-card";
import { ProductRow } from "../modules/product-row";
import { TitleAndText } from "../modules/title-and-text";
import { Activities } from "../modules/activities";
import { ActivityCover } from "../modules/activity-cover";
import { ActivityLinkedSection } from "../modules/activity-linked-section";
import { LinkedSectionCover } from "../modules/linked-section-cover";
import { CustomOrderForm } from "../modules/custom-order-form";
import { RefundForm } from "../modules/refund-form";
import { LegalNoticeAddress } from "../modules/legal-notice-address";
import { Teams } from "../modules/teams";
import { Collections } from "../modules/collections";
import { Video } from "../modules/video";

export const DetermineModule = ({
  content,
  index,
  pageType,
  articleCategories,
  activityLinkedSections,
  activityCategories,
  currentLanguage,
}) => {
  if (content.slice_type === "custom_order_form") {
    return (
      <CustomOrderForm
        key={`single_page_item_${index}_${content.id}`}
        content={content}
        currentLanguage={currentLanguage}
      />
    );
  }

  if (content.slice_type === "refund_form") {
    return (
      <RefundForm
        key={`single_page_item_${index}_${content.id}`}
        content={content}
        currentLanguage={currentLanguage}
      />
    );
  }

  if (content.slice_type === "legal_notice_address") {
    return (
      <LegalNoticeAddress
        key={`single_page_item_${index}_${content.id}`}
        content={content}
      />
    );
  }

  if (content.slice_type === "activity_cover") {
    return (
      <ActivityCover
        key={`single_page_item_${index}_${content.id}`}
        activityTitle={content.primary.activity_title.richText}
        activityText={content.primary.activity_text.richText}
        activityImage={content.primary.activity_image}
        activityLinkedSections={activityLinkedSections}
        activityCategories={activityCategories}
        index={index}
      />
    );
  }

  if (content.slice_type === "linked_section_cover") {
    return (
      <LinkedSectionCover
        key={`single_page_item_${index}_${content.id}`}
        title={content.primary.activity_title.richText}
        text={content.primary.activity_text.richText}
        image={content.primary.activity_image}
        linkedSections={activityLinkedSections}
        index={index}
      />
    );
  }

  if (content.slice_type === "linked_section") {
    return (
      <ActivityLinkedSection
        key={`single_page_item_${index}_${content.id}`}
        content={content}
        index={index}
      />
    );
  }

  if (content.slice_type === "big_push") {
    return (
      <BigPush
        key={`single_page_item_${index}_${content.id}`}
        bigPushTitle={content.primary.big_push_title.richText}
        callToAction={content.primary.call_to_action.richText}
        article={content.primary.article}
        currentLanguage={currentLanguage}
        index={index}
      />
    );
  }

  if (content.slice_type === "cover") {
    return (
      <Cover
        key={`single_page_item_${index}_${content.id}`}
        coverTitle={content.primary.cover_title.richText}
        coverText={content.primary.cover_text.richText}
        coverImage={content.primary.cover_image}
        coverCallToAction={content.primary.cover_call_to_action.richText}
        coverLink={content.primary.link.url}
        imagePosition={content.primary.image_position}
        index={index}
      />
    );
  }

  if (content.slice_type === `video` && content.primary.video !== null) {
    return (
      <Video
        video={content.primary.video}
        videoSize={content.primary.video_size}
        id={content.primary.video.id}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_cover") {
    return (
      <EditorialCover
        key={`single_page_item_${index}_${content.id}`}
        id={content.id}
        articleCategories={pageType === `article` ? articleCategories : null}
        editorialCoverImage={content.primary.image}
        editorialTitle={content.primary.editorial_title.richText}
        editorialText={content.primary.editorial_text.richText}
        editorialCredits={content.primary.editorial_credits.richText}
        imagePosition={content.primary.image_position.toLowerCase()}
        index={index}
        pageType={pageType}
      />
    );
  }

  if (content.slice_type === "edit") {
    if (
      content.primary.journal_edit.document !== undefined &&
      content.primary.journal_edit.document !== null
    ) {
      if (content.primary.journal_edit.type === `journal_edit`) {
        return (
          <Edit
            key={`single_page_item_${index}_${content.id}`}
            editTitle={
              content.primary.journal_edit.document.data.title.richText
            }
            editArticles={content.primary.journal_edit.document.data.articles.filter(
              (article) => article.article.document !== null
            )}
            editBg={content.primary.background_color}
            currentLanguage={currentLanguage}
            index={index}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  if (content.slice_type === "editorial_image") {
    return (
      <EditorialImage
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        editorialImage={content.primary.image}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_image_with_text") {
    return (
      <EditorialImageWithText
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_images") {
    return (
      <EditorialImages
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_note") {
    return (
      <EditorialNote
        key={`single_page_item_${index}_${content.id}`}
        title={content.primary.editorial_note_title}
        text={content.primary.editorial_note_text}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_introduction") {
    return (
      <EditorialIntroduction
        text={content.primary.editorial_introduction_text}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_quote") {
    return (
      <EditorialQuote
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "editorial_row") {
    return (
      <EditorialRow
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
        currentLanguage={currentLanguage}
      />
    );
  }

  if (content.slice_type === "editorial_text") {
    return (
      <EditorialText
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "product_card_large") {
    return (
      <ProductCard
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        currentLanguage={currentLanguage}
        index={index}
      />
    );
  }

  if (content.slice_type === "product_row") {
    return (
      <ProductRow
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
        currentLanguage={currentLanguage}
      />
    );
  }

  if (content.slice_type === "product_row_carousel") {
    return (
      <ProductCarousel
        content={content}
        index={index}
        key={`single_page_item_${index}_${content.id}`}
        currentLanguage={currentLanguage}
      />
    );
  }

  if (content.slice_type === "push_story") {
    if (
      content.primary.story.document !== undefined &&
      content.primary.story.document !== null
    ) {
      if (content.primary.story.document.data !== undefined) {
        return (
          <PushStory
            content={content}
            key={`single_page_item_${index}_${content.id}`}
            currentLanguage={currentLanguage}
            index={index}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  if (content.slice_type === "title_and_text") {
    return (
      <TitleAndText
        content={content}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "newsletter") {
    if (
      content.primary.newsletter.document !== undefined &&
      content.primary.newsletter.document !== null
    ) {
      return (
        <Newsletter
          key={`single_page_item_${index}_${content.id}`}
          newsletterBgColor={
            content.primary.newsletter.document.data.background_color
          }
          newsletterEmailPlaceholder={
            content.primary.newsletter.document.data.email_placeholder
          }
          newsletterText={
            content.primary.newsletter.document.data.newsletter_text.richText
          }
          index={index}
          currentLanguage={currentLanguage}
        />
      );
    } else {
      return null;
    }
  }

  if (content.slice_type === "activities") {
    return (
      <Activities
        id={content.id}
        activityTitle={content.primary.activity_title.richText}
        activityText={content.primary.activity_text.richText}
        key={`single_page_item_${index}_${content.id}`}
        index={index}
      />
    );
  }

  if (content.slice_type === "teams") {
    return (
      <Teams
        id={content.id}
        key={`single_page_item_${index}_${content.id}`}
        content={content}
        index={index}
      />
    );
  }

  if (content.slice_type === "collections") {
    return (
      <Collections
        id={content.id}
        key={`single_page_item_${index}_${content.id}`}
        content={content}
        index={index}
      />
    );
  }

  return null;
};
