import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

// Context
import { StoreContext } from "../context/store";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

// Menu Data
import { UseEnglishMenuData } from "../hooks/useEnglishMenuData";
import { UseFrenchMenuData } from "../hooks/useFrenchMenuData";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  border-bottom: 1px solid #09184f;

  z-index: 200;
`;

export const Header = ({
  location,
  currentLanguage,
  setCurrentLanguage,
  isDesktop,
  headerColor,
  setHeaderColor,
  prevHeaderColor,
  setPageContext,
  pageColor,
}) => {
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  const englishMenuData = UseEnglishMenuData();
  const frenchMenuData = UseFrenchMenuData();

  const [menuData, setMenuData] = useState(englishMenuData);

  useEffect(() => {
    if (currentLanguage === `en`) {
      setMenuData(englishMenuData);
    } else {
      setMenuData(frenchMenuData);
    }
  }, [currentLanguage]);

  // bgColor={headerColor}
  return (
    <HeaderWrapper>
      {isDesktop ? (
        <DesktopMenu
          data={menuData}
          location={location}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          setHeaderColor={setHeaderColor}
          headerColor={headerColor}
          prevHeaderColor={prevHeaderColor}
          setPageContext={setPageContext}
          quantity={quantity}
          pageColor={pageColor}
        />
      ) : (
        <MobileMenu
          data={menuData}
          location={location}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          setHeaderColor={setHeaderColor}
          headerColor={headerColor}
          prevHeaderColor={prevHeaderColor}
          setPageContext={setPageContext}
          quantity={quantity}
          pageColor={pageColor}
        />
      )}
    </HeaderWrapper>
  );
};
