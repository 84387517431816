import React from "react";
import { Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  & .button-container {
    display: grid;
    grid-template-columns: 90px 90px;
    grid-column-gap: 20px;
    align-items: flex-start;
  }

  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 100;
    padding: 10px 15px;

    display: grid;
    grid-template-columns: 1fr 200px;
    grid-column-gap: 20px;
    /* align-items: center; */

    font-size: 14px;
    line-height: 24px;

    background-color: #09184f;
    color: #fff;

    & .cookie-text {
      & p {
        margin: 0;
        color: #fff;
      }
    }

    & .cookie-container {
      display: inline-block;
    }

    & .content-container {
    }

    & .cookie-text > p {
      margin: 0;
      color: #fff;
    }

    & #rcc-decline-button,
    & .cookie-button {
      display: block;

      padding: 5px;
      margin: 0;

      cursor: pointer;
      border: 1px solid #fff;

      transition: 250ms background-color ease, 250ms color ease;

      &:hover {
        background-color: #fff;
        color: #09184f;
      }
    }
  }

  // 700px media query
  @media (max-width: 700px) {
    & .cookie-container {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      padding: 10px 15px 30px 15px;

      & .content-container {
        margin: 0 0 10px 0;
      }
    }
  }
`;

export const Cookies = ({ location, currentLanguage }) => {
  return (
    <CookieContainer>
      <CookieConsent
        location="bottom"
        buttonText={currentLanguage === `en` ? `Accept` : `Accepter`}
        declineButtonText={currentLanguage === `en` ? `Decline` : `Refuser`}
        cookieName="rinck-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        enableDeclineButton={true}
        expires={150}
      >
        <div className={`cookie-text`}>
          {currentLanguage === "en" ? (
            <p>
              This website uses cookies to enhance the user experience. Close
              this dialog to confirm you are happy with that, or find out more
              in our <Link to={`/en/privacy-policy/`}>privacy policy</Link>.
            </p>
          ) : (
            <p>
              o Ce site web utilise des cookies pour améliorer l'expérience de
              l'utilisateur. Fermez cette boîte de dialogue pour confirmer que
              vous êtes satisfait ou pour en savoir plus sur notre{" "}
              <Link to={`/fr/privacy-policy/`}>
                politique de confidentialité.
              </Link>
            </p>
          )}
        </div>
      </CookieConsent>
    </CookieContainer>
  );
};
