import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Modules
import { SingleArticle } from "./single-article";

const EditorialRowContainer = styled.div`
  @media (max-width: 800px) {
    &.module {
      padding: 0;
    }
  }

  & .title {
    margin: 0 0 100px 0;

    & h2 {
      font-size: 60px;
      line-height: 65px;

      @media (max-width: 999px) {
        font-size: 40px;
        line-height: 50px;
      }
    }

    @media (max-width: 999px) {
      margin: 0 0 75px 0;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }
  }

  & .articles-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 50px;

    @media (max-width: 1445px) {
      grid-column-gap: 40px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 30px;
    }

    @media (max-width: 800px) {
      display: flex;
      flex-direction: row;

      max-width: 100%;
      grid-template-columns: unset;
      grid-column-gap: unset;

      overflow-x: scroll;
      scroll-snap-type: x mandatory;

      padding: 0 25px;

      &::-webkit-scrollbar {
        display: none;
      }

      & .single-article {
        min-width: 50vw;
        margin: 0 40px 0 0;
        scroll-snap-align: center;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    @media (max-width: 500px) {
      & .single-article {
        min-width: 70vw;
        margin: 0 25px 0 0;
      }
    }
  }
`;

export const EditorialRow = ({ content, index, currentLanguage }) => {
  const articles = content.items
    .filter((article) => article.article.document !== undefined)
    .filter((article) => article.article.document !== null)
    .filter((article) => Object.keys(article.article.document).length !== 0)
    .map((article, innerIndex) => (
      <SingleArticle
        article={article.article.document}
        key={`single_article_${innerIndex}_module_${index}`}
        currentLanguage={currentLanguage}
      />
    ));

  return (
    <EditorialRowContainer className="module editorial-row">
      <div className="title center">
        <PrismicRichText field={content.primary.editorial_row_title.richText} />
      </div>

      <div className="articles-container">{articles}</div>
    </EditorialRowContainer>
  );
};
