import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const UseEnglishActivitiesData = () => {
  const englishActivitiesData = useStaticQuery(graphql`
    {
      prismicActivitiesModule(lang: { eq: "en-gb" }) {
        data {
          activities {
            activity {
              document {
                ... on PrismicActivityCategory {
                  id
                  url
                  data {
                    title {
                      richText
                    }
                    text {
                      richText
                    }
                    thumbnail {
                      alt
                      dimensions {
                        height
                        width
                      }
                      gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return englishActivitiesData;
};
