import React, { useContext, useEffect } from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../../components/global/page-seo";

// Components
import { AspectRatioImageContainer } from "../../components/containers/aspect-ratio-image-container";

// Modules
import { Newsletter } from "../../components/modules/newsletter";
import { SingleArticle } from "../../components/modules/single-article";

// Context
import { CurrentLanguage } from "../../components/context/current-language";
import { PageColor } from "../../components/context/page-color";
import { HeaderColor } from "../../components/context/header-color";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = styled.div`
  padding: 0 50px;

  @media (max-width: 1445px) {
    padding: 0 40px;
  }

  @media (max-width: 999px) {
    padding: 0 30px;
  }

  @media (max-width: 800px) {
    padding: 0 25px;

    &.page {
      margin: 120px 0 0 0;
    }
  }

  & .remaining-articles-container {
    margin: 0 0 150px 0;

    @media (max-width: 800px) {
      & .single-article {
        grid-column: span 4;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 25px;

        margin: 0 0 70px 0;

        &:last-of-type {
          margin: 0;
        }

        & .image-container {
          grid-column: span 3;
        }

        & .text-container {
          grid-column: span 3;
          margin: 0;

          & .article-title {
            font-size: 19px;
            line-height: 26px;

            margin: 10px 0 0 0;
          }

          & .article-excerpt {
            margin: 20px 0;
          }
        }
      }
    }

    @media (max-width: 500px) {
      & .single-article {
        & .image-container {
          grid-column: span 2;
        }

        & .text-container {
          grid-column: span 4;
        }
      }
    }
  }

  & .view-more-articles-container {
    width: 100%;

    & button {
      display: block;
      margin: 0 auto;

      border: 1px solid #09184f;

      height: 50px;
      line-height: 50px;
      width: 275px;
    }
  }
`;

const Introduction = styled.div`
  padding: 30px 30px 0 30px;

  @media (max-width: 1445px) {
    padding: 0;
  }

  &.add-margin {
    margin: 0 0 200px 0;

    @media (max-width: 800px) {
      margin: 0 0 90px 0;
    }
  }

  & .mobile-title {
    grid-column: span 6;
    display: none;

    margin: 0 0 50px 0;

    @media (max-width: 800px) {
      display: block;
    }
  }

  & .image-container {
    grid-column: 1 / 7;
    padding: 0 25px 0 0;

    @media (max-width: 1445px) {
      padding: 0;
    }

    @media (max-width: 800px) {
      grid-column: 1 / 7;
      order: 1;
    }

    & img:not([aria-hidden="true"]) {
      border: 39px solid #efefef;
      box-sizing: border-box;

      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }
  }

  & .text-container {
    grid-column: 7 / 13;
    margin: 39px 0 0 0;
    padding: 0 0 0 25px;

    @media (max-width: 1445px) {
      grid-column: 7 / 13;
      padding: 39px 0;
      margin: 0 0 0 60px;
    }

    @media (max-width: 999px) {
    }

    @media (max-width: 800px) {
      & .category-title-container {
        display: none;
      }

      grid-column: 1 / 7;
      order: 2;

      padding: 39px 25px;
      margin: 0;
    }

    & .inner-text-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 100%;

      max-width: 470px;

      & .category-title-container {
        width: 100%;
      }

      & .first-article-text-container {
        & .title-bar {
          display: flex;
          flex-direction: row;

          & p {
            margin: 0;
          }

          & .spacer {
            margin: 0 10px;
          }
        }

        & .text {
          & .article-title {
            margin: 20px 0;
          }

          & .article-excerpt {
            margin: 20px 0;
          }
        }
      }
    }
  }
`;

const JournalCategory = ({ data }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const Article = ({ article, index }) => (
    <SingleArticle
      article={article}
      key={`single_article_module_${index}`}
      currentLanguage={currentLanguage}
    />
  );

  const firstArticle =
    data.allPrismicArticle.nodes.length >= 1
      ? data.allPrismicArticle.nodes.filter((article, index) => index === 0)[0]
      : null;

  const remainingArticles =
    data.allPrismicArticle.nodes.length >= 1
      ? data.allPrismicArticle.nodes
          .filter((article, index) => index > 0)
          .map((article, index) => (
            <Article
              article={article}
              key={`single_article_category_${index}_remaining_articles`}
            />
          ))
      : null;

  if (firstArticle !== null) {
    return (
      <>
        <PageSeo
          title={data.prismicJournalCategory.data.title.text}
          image={null}
          description={null}
          url={data.prismicJournalCategory.url}
        />

        <Page className="page">
          <Introduction
            className={remainingArticles.length >= 1 ? `add-margin` : ``}
          >
            <div className="grid-12">
              <div className="mobile-title">
                <div className="category-title-container">
                  <PrismicRichText
                    field={data.prismicJournalCategory.data.title.richText}
                  />
                </div>
              </div>

              <div className="image-container">
                <Link to={firstArticle.url}>
                  <AspectRatioImageContainer image={null} padding={140}>
                    {firstArticle.data.thumbnail.fluid !== null && (
                      <GatsbyImage
                        image={firstArticle.data.thumbnail.gatsbyImageData}
                        alt={
                          firstArticle.data.thumbnail.alt !== null
                            ? firstArticle.data.thumbnail.alt
                            : ``
                        }
                        fetchpriority={`high`}
                        loading={`eager`}
                      />
                    )}
                  </AspectRatioImageContainer>
                </Link>
              </div>

              <div className="text-container">
                <div className="inner-text-container">
                  <div className="category-title-container">
                    <PrismicRichText
                      field={data.prismicJournalCategory.data.title.richText}
                    />
                  </div>

                  <div className="first-article-text-container">
                    <div className="text">
                      <div className="title-bar tag gotham-bold uppercase">
                        <p>
                          <Link to={`/${currentLanguage}/journal/`}>
                            Journal
                          </Link>
                        </p>
                        <p className="spacer">|</p>
                        <p>
                          <Link to={data.prismicJournalCategory.url}>
                            {data.prismicJournalCategory.data.title.text}
                          </Link>
                        </p>
                      </div>

                      <PrismicRichText
                        field={firstArticle.data.title.richText}
                        components={{
                          heading1: ({ children, index }) => (
                            <h3
                              className="article-title"
                              key={`single_h3_${index}`}
                            >
                              {children}
                            </h3>
                          ),
                        }}
                      />
                      <PrismicRichText
                        field={firstArticle.data.excerpt_text.richText}
                        components={{
                          paragraph: ({ children, index }) => (
                            <p
                              className="article-excerpt"
                              key={`single_paragraph_${index}`}
                            >
                              {children}
                            </p>
                          ),
                        }}
                      />

                      <p>
                        <Link to={firstArticle.url} className="uppercase small">
                          {currentLanguage === `en` ? `See More` : `Voir Plus`}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Introduction>

          {remainingArticles.length >= 1 && (
            <>
              <section className="grid-4 remaining-articles-container">
                {remainingArticles}
              </section>
            </>
          )}
        </Page>
      </>
    );
  } else {
    return null;
  }
};

export default withPrismicPreview(JournalCategory);

export const query = graphql`
  query JournalCategoryQuery($id: String, $lang: String, $uid: String) {
    allPrismicArticle(
      filter: {
        lang: { eq: $lang }
        data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } }
      }
    ) {
      nodes {
        _previewable
        id
        url
        data {
          title {
            richText
          }
          excerpt_text {
            richText
          }
          thumbnail {
            alt
            dimensions {
              height
              width
            }
            fluid {
              src
              srcSet
              aspectRatio
            }
            gatsbyImageData
          }
          categories {
            category {
              document {
                ... on PrismicJournalCategory {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicJournalCategory(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          richText
          text
        }
      }
    }
  }
`;
