import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Styles
import "../styles/plyr.css";
import kebabCase from "lodash.kebabcase";

const VideoContainer = styled.div`
  position: relative;
  z-index: 10;

  padding: 0 40px;

  & .plyr {
    /* border-radius: 11px; */
  }

  & .youtube-video-container,
  & .matterport-video-container {
    position: relative;

    width: 100%;
    padding-bottom: 56.25%;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* border-radius: 11px; */
    }
  }

  & .video-size-half-width {
    width: 66.666%;
    margin: 0 auto;

    @media (max-width: 1445px) {
      width: 83.333%;
    }

    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

export const Video = ({ video, id, videoSize }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-${id}`, {
          autoplay: true,
          autopause: true,
          loop: { active: true },
          hideControls: true,
          clickToPlay: false,
          muted: true,
          volume: 1,
          playsinline: true,
          fullscreen: { enabled: true, fallback: true, iosNative: true },
          // storage: { enabled: false, key: "plyr" },
          captions: { active: true, language: "en", update: false },
          vimeo: {
            autopause: false,
            byline: false,
            controls: false,
            autoplay: true,
          },
          youtube: {
            playsinline: true,
            cc_load_policy: true,
          },
        })
      );
    }
  }, []);

  if (video !== null) {
    if (video.provider_name === `YouTube`) {
      return (
        <VideoContainer className="video-container module">
          <div
            className={`video-size-${
              videoSize !== null ? kebabCase(videoSize) : `full-width`
            }`}
          >
            <div
              className={`plyr__video-embed youtube-video-container`}
              id={`video-${id}`}
              dangerouslySetInnerHTML={{
                __html: video.html,
              }}
            />
          </div>
        </VideoContainer>
      );
    } else if (video.provider_name === `Matterport`) {
      return (
        <VideoContainer className={`video-container module`}>
          <div
            className={`video-size-${
              videoSize !== null ? kebabCase(videoSize) : `full-width`
            }`}
          >
            <div
              className={`matterport-video-container`}
              id={`video-${id}`}
              dangerouslySetInnerHTML={{
                __html: video.html,
              }}
            />
          </div>
        </VideoContainer>
      );
    } else {
      return (
        <VideoContainer className={`video-container module`}>
          <div
            className={`video-size-${
              videoSize !== null ? kebabCase(videoSize) : `full-width`
            }`}
          >
            <div
              className={`plyr__video-embed`}
              id={`video-${id}`}
              dangerouslySetInnerHTML={{
                __html: video.html,
              }}
            />
          </div>
        </VideoContainer>
      );
    }
  } else {
    return null;
  }
};
