import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useLockBodyScroll } from "react-use";
import { use100vh } from "react-div-100vh";

// Icons
import { Logo } from "../icons/logo";
import {
  BagIcon,
  SearchIcon,
  MenuIcon,
  CloseMenuIcon,
  BackIcon,
} from "../icons/mobile-icons";

// Components
import { LanguageSwitcher } from "./language-switcher";
import SearchIndex from "../search/search-index";

const Nav = styled.nav`
  & .top-navigation-container {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: ${(props) => props.bgColor};
    transition: 250ms background-color ease;

    z-index: 10;

    @media (max-width: 1445px) {
      padding: 0 40px;
    }

    @media (max-width: 999px) {
      padding: 0 30px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }
  }

  & .logo-container {
    line-height: 78px;

    & a {
      display: flex;
      width: fit-content;

      margin: 0 auto;

      height: 38px;
    }
  }

  & ol.top-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    line-height: 78px;

    &.left-links {
    }

    /* &. {
      justify-content: flex-end;
    } */

    & li {
      margin: 0 20px 0 0;
      cursor: pointer;

      font-size: 16px;

      & button,
      & a {
        line-height: 1;
      }

      &:last-of-type {
        margin: 0;
      }

      & button {
        &.menu-icon {
          width: 21px;
        }
      }

      &.language-toggle {
        margin: 0 0 0 10px;

        & button {
          font-size: 9px;
          line-height: 12px;
          border-bottom: 1px solid transparent;

          cursor: pointer;

          &:first-of-type {
            margin: 0 10px 0 0;
          }

          &:hover,
          &.active {
            border-bottom: 1px solid #09184f;
          }
        }
      }
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 77px;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1;

  &.closed {
    transform: translateY(calc(-${(props) => props.height}px - 78px - 78px));
  }

  &.open {
    transform: translateY(0);
  }

  transition: 500ms transform ease;

  & .links-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;

    position: relative;
    min-height: calc(${(props) => props.height}px - 78px - 50px);

    &.search-open {
      min-height: 50vh;
    }

    background-color: #fff;

    padding: 40px 25px;

    z-index: 1;

    & .panel-1 {
      width: 100%;

      & .top-section {
        & ol {
          & li {
            font-size: 30px;
            line-height: 40px;
          }
        }
      }

      & .middle-section {
        border-top: 1px solid rgba(145, 132, 15, 0.1);
        border-bottom: 1px solid rgba(145, 132, 15, 0.1);

        margin: 30px 0;
        padding: 25px 0;

        & ol {
          & li {
            font-size: 30px;
            line-height: 40px;
          }
        }
      }

      & .bottom-section {
        & button {
          font-size: 11px;
          line-height: 12px;
          border-bottom: 1px solid transparent;

          cursor: pointer;

          &:first-of-type {
            margin: 0 10px 0 0;
          }

          &:hover,
          &.active {
            border-bottom: 1px solid #09184f;
          }
        }
      }
    }

    & .panel-2,
    & .panel-3 {
      & button {
        &.back-button {
          display: flex;
          align-items: center;

          margin: 0 0 55px 0;

          & svg {
            margin: 0 15px 0 0;
          }
        }
      }

      & ol {
        &.inner-section-links {
          & li {
            font-size: 30px;
            line-height: 40px;
          }

          &.top {
            border-bottom: 1px solid rgba(145, 132, 15, 0.1);

            margin: 0 0 20px 0;
            padding: 0 0 20px 0;
          }

          &.bottom {
            border-top: 1px solid rgba(145, 132, 15, 0.1);

            margin: 20px 0 0 0;
            padding: 20px 0 0 0;
          }
        }
      }
    }
  }
`;

const Fill = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #09184f;
  transition: 500ms opacity ease;

  z-index: 0;
  pointer-events: none;

  &.closed {
    opacity: 0;
  }

  &.open {
    opacity: 0.85;
  }
`;

export const MobileMenu = ({
  currentLanguage,
  setCurrentLanguage,
  data,
  location,
  setHeaderColor,
  headerColor,
  prevHeaderColor,
}) => {
  const height = use100vh();

  // Is mobile menu open?
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Active Menu
  const [activeMenu, setActiveMenu] = useState(`overview`);
  const [activeInnerMenuTitle, setActiveInnerMenuTitle] = useState(null);

  // Active Inner Menu
  const [activeInnerMenu, setActiveInnerMenu] = useState(null);

  useEffect(() => {
    setIsMenuOpen(false);
    setActiveMenu(`overview`);
    setActiveInnerMenu(null);
    setActiveInnerMenuTitle(null);
  }, [location]);

  useEffect(() => {
    if (isMenuOpen) {
      setHeaderColor(`#fff`);
    } else {
      setHeaderColor(prevHeaderColor);
    }
  }, [isMenuOpen]);

  useLockBodyScroll(isMenuOpen);

  // Section Links
  const shopLinks = data.prismicMenu.data.body.map((link, index) => {
    if (link.slice_type === "menu_item_with_subpages") {
      return (
        <li key={`single_shop_section_${index}`} className={`classico`}>
          <button
            className="title"
            onClick={() => {
              if (activeInnerMenu !== index) setActiveInnerMenu(index);
              setActiveInnerMenuTitle(link.primary.title.text);
            }}
          >
            {link.primary.title.text}
          </button>
        </li>
      );
    } else {
      return (
        <li
          key={`single_shop_section_${index}`}
          className={`classico single-link-section`}
        >
          <Link to={link.primary.page.url}>
            {link.primary.page.document.data.title.text}
          </Link>
        </li>
      );
    }
  });

  const shopLinksSubMenu = data.prismicMenu.data.body.map((link, index) => {
    if (link.slice_type === "menu_item_with_subpages") {
      return link.items
        .filter((innerLink) => innerLink.link.document !== null)
        .map((innerLink, innerIndex) => (
          <li
            key={`single_link_${index}_${innerLink.link.document.id}`}
            className={`classico single-link-section`}
          >
            <Link to={innerLink.link.url}>
              {innerLink.link.document.data.title.text}
            </Link>
          </li>
        ));
    } else {
      return [];
    }
  });

  const activitiesLinks = data.prismicMenu.data.body1.map((link, index) => {
    return (
      <li key={`single_activities_section_${index}`} className={`classico`}>
        <div
          className="title"
          onClick={() => {
            if (activeInnerMenu !== index) setActiveInnerMenu(index);
            setActiveInnerMenuTitle(link.primary.title.text);
          }}
        >
          <Link to={`${link.primary.page.url}`}>{link.primary.title.text}</Link>
        </div>
      </li>
    );
  });

  const activitiesLinksSubMenu = data.prismicMenu.data.body1.map(
    (link, index) => {
      return link.items
        .filter((innerLink) => innerLink.link.document !== null)
        .map((innerLink, innerIndex) => (
          <li key={`single_link_${index}_${innerLink.link.document.id}`}>
            <Link to={innerLink.link.url}>
              {innerLink.link.document.data.title.text}
            </Link>
          </li>
        ));
    }
  );

  const journalLinks = data.prismicMenu.data.body2.map((link, index) => {
    return (
      <li key={`single_journal_section_${index}`} className="classico">
        <Link to={`${link.primary.page.url}`}>
          {link.primary.page.document.data.title.text}
        </Link>
      </li>
    );
  });

  return (
    <Nav bgColor={headerColor}>
      <div className="top-navigation-container">
        <div className="logo-container">
          <Link
            to={currentLanguage === `en` ? `/` : `/${currentLanguage}/`}
            onClick={() => setIsMenuOpen(false)}
          >
            <Logo />
          </Link>
        </div>

        <ol className="classico top-links">
          <li>
            <button
              onClick={() => {
                if (activeMenu !== `search`) setActiveMenu(`search`);
                setIsMenuOpen(true);
              }}
            >
              <SearchIcon />
            </button>
          </li>

          <li>
            <Link aria-label="cart" to={`/${currentLanguage}/cart/`}>
              <BagIcon />
            </Link>
          </li>

          <li>
            <button
              className="menu-icon"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
                setActiveMenu(`overview`);
              }}
            >
              {!isMenuOpen ? <MenuIcon /> : <CloseMenuIcon />}
            </button>
          </li>
        </ol>
      </div>

      <Menu height={height} className={isMenuOpen ? `open` : `closed`}>
        <div
          className={`links-container ${
            activeMenu === `search` ? `search-open` : ``
          }`}
        >
          {activeMenu === `search` && (
            <SearchIndex setActiveMenu={setActiveMenu} />
          )}

          {activeMenu === `overview` && (
            <div className="panel-1">
              <div className="top-section">
                <ol className="classico">
                  <li className={activeMenu === `shop` ? `current` : ``}>
                    <button
                      onClick={() => {
                        if (activeMenu !== `shop`) setActiveMenu(`shop`);
                      }}
                    >
                      {data.prismicMenu.data.shop_menu_title.text}
                    </button>
                  </li>

                  <li
                    className={activeMenu === `activities` ? `current` : ``}
                    onClick={() => {
                      if (activeMenu !== `activities`)
                        setActiveMenu(`activities`);
                    }}
                  >
                    {data.prismicMenu.data.activities_menu_title.text}
                  </li>

                  <li
                    className={activeMenu === `journal` ? `current` : ``}
                    onClick={() => {
                      if (activeMenu !== `journal`) setActiveMenu(`journal`);
                    }}
                  >
                    {data.prismicMenu.data.journal_menu_title.text}
                  </li>
                </ol>
              </div>

              <div className="middle-section">
                <ol className="classico">
                  <li>
                    <Link
                      aria-label="account"
                      to={`/${currentLanguage}/account/login/`}
                    >
                      {currentLanguage === `en` ? `Account` : `Compte`}
                    </Link>
                  </li>
                </ol>
              </div>

              <div className="bottom-section language-toggle">
                <ol>
                  <LanguageSwitcher
                    currentLanguage={currentLanguage}
                    setCurrentLanguage={setCurrentLanguage}
                    location={location}
                  />
                </ol>
              </div>
            </div>
          )}

          {activeInnerMenu === null && (
            <div className="panel-2">
              {activeMenu === `shop` && (
                <>
                  <button
                    onClick={() => setActiveMenu(`overview`)}
                    className="gotham-bold tag uppercase back-button"
                  >
                    <BackIcon /> Shop
                  </button>
                  <ol className="inner-section-links">{shopLinks}</ol>
                </>
              )}

              {activeMenu === `activities` && (
                <>
                  <button
                    onClick={() => setActiveMenu(`overview`)}
                    className="gotham-bold tag uppercase back-button"
                  >
                    <BackIcon /> Activities
                  </button>
                  <ol className="inner-section-links">{activitiesLinks}</ol>
                </>
              )}

              {activeMenu === `journal` && (
                <>
                  <button
                    onClick={() => setActiveMenu(`overview`)}
                    className="gotham-bold tag uppercase back-button"
                  >
                    <BackIcon /> Journal
                  </button>

                  <ol className="inner-section-links top">
                    <li className="classico">
                      <Link
                        aria-label="Journal"
                        to={`/${currentLanguage}/journal/`}
                      >
                        {currentLanguage === `en`
                          ? `All articles`
                          : `Tous les articles`}
                      </Link>
                    </li>
                  </ol>
                  <ol className="inner-section-links">{journalLinks}</ol>

                  {data.prismicMenu.data.featured_edits_link.document !==
                    null && (
                    <ol className="inner-section-links bottom">
                      <li className="classico">
                        <Link
                          aria-label={
                            data.prismicMenu.data.featured_edits_link.document
                              .data.title.text
                          }
                          to={
                            data.prismicMenu.data.featured_edits_link.document
                              .url
                          }
                        >
                          {
                            data.prismicMenu.data.featured_edits_link.document
                              .data.title.text
                          }
                        </Link>
                      </li>
                    </ol>
                  )}
                </>
              )}
            </div>
          )}

          {activeInnerMenu !== null && (
            <div className="panel-3">
              {activeMenu === `shop` && (
                <>
                  <button
                    onClick={() => {
                      setActiveMenu(`shop`);
                      setActiveInnerMenu(null);
                    }}
                    className="gotham-bold tag uppercase back-button"
                  >
                    <BackIcon /> Shop | {activeInnerMenuTitle}
                  </button>
                  <ol className="inner-section-links">
                    {shopLinksSubMenu[activeInnerMenu]}

                    {activeInnerMenuTitle === `Collections` && (
                      <li className={`classico single-link-section`}>
                        <Link to={`/${currentLanguage}/collections/`}>
                          {currentLanguage === `en` ? `See More` : `Voir Plus`}
                        </Link>
                      </li>
                    )}

                    {(activeInnerMenuTitle === `Furniture` ||
                      activeInnerMenuTitle === `Mobilier`) && (
                      <li className={`classico single-link-section`}>
                        <Link to={`/${currentLanguage}/furniture/`}>
                          {currentLanguage === `en` ? `See More` : `Voir Plus`}
                        </Link>
                      </li>
                    )}
                  </ol>
                </>
              )}
              {activeMenu === `activities` && (
                <>
                  <button
                    onClick={() => {
                      setActiveMenu(`activities`);
                      setActiveInnerMenu(null);
                    }}
                    className="gotham-bold tag uppercase back-button"
                  >
                    <BackIcon /> Activities | {activeInnerMenuTitle}
                  </button>
                  <ol className="inner-section-links">
                    {activitiesLinksSubMenu[activeInnerMenu]}
                  </ol>
                </>
              )}
            </div>
          )}
        </div>
      </Menu>

      <Fill className={isMenuOpen ? `open` : `closed`} />
    </Nav>
  );
};
