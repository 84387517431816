import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { useMeasure } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Icons
import { ArrowNext, ArrowPrev } from "../icons/arrows";

const ProductCarouselContainer = styled.div`
  margin: 0 0 150px 0;

  & .title-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    margin: 0 0 30px 160px;
    padding: 0 50px 0 0;

    @media (max-width: 1445px) {
      margin: 0 0 30px 115px;
      padding: 0 40px 0 0;
    }

    @media (max-width: 999px) {
      margin: 0 0 30px 100px;
      padding: 0 30px 0 0;
    }

    @media (max-width: 800px) {
      margin: 0 0 30px 25px;
      padding: 0 25px 0 0;
    }

    & .navigation {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      @media (max-width: 500px) {
        display: none;
      }

      & button {
        padding: 0;
        width: 25px;
        height: 11px;

        line-height: 1;

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          text-align: right;
        }

        & svg {
          height: 13px;
          width: auto;
        }
      }

      & svg {
        &.line {
          height: 11px;
          width: auto;

          margin: 0 5px;
        }
      }

      & p {
        font-feature-settings: "tnum";

        margin: 0;

        &:last-of-type {
          text-align: right;
        }

        &.spacer {
          text-align: center;
          width: 20px;
        }
      }
    }
  }

  & .single-product {
    & img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    & .text-container {
      margin: 20px 0 0 0;

      & h4,
      & p {
        font-size: 14px;
        line-height: 25px;

        margin: 0;
      }
    }
  }

  & .slide-container {
    display: flex;
    flex-direction: row;

    max-width: 100%;
    grid-template-columns: unset;
    grid-column-gap: unset;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    & .single-product {
      min-width: 31vw;
      width: 31vw;

      margin: 0 40px 0 0;
      scroll-snap-align: center;

      @media (max-width: 999px) {
        margin: 0 25px 0 0;
      }

      &:first-of-type {
        margin: 0 40px 0 160px;

        @media (max-width: 1445px) {
          margin: 0 40px 0 115px;
        }

        @media (max-width: 999px) {
          margin: 0 25px 0 100px;
        }

        @media (max-width: 800px) {
          margin: 0 25px 0 25px;
        }
      }

      &:last-of-type {
        margin: 0 50px 0 0;

        @media (max-width: 1445px) {
          margin: 0 40px 0 0;
        }

        @media (max-width: 999px) {
          margin: 0 30px 0 0;
        }

        @media (max-width: 800px) {
          margin: 0 25px 0 0;
        }
      }

      @media (max-width: 500px) {
        min-width: 80vw;
        margin: 0 25px 0 0;
      }
    }
  }
`;

const SingleProduct = ({ item, setItemWidth, currentLanguage }) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  useEffect(() => {
    setItemWidth(width);
  }, [width]);

  if (item.product.document.data === undefined) return null;

  return (
    <div className="single-product" ref={ref}>
      <div className="image-container">
        <Link
          to={`/${currentLanguage}/products/${item.product.document.data.shopify_product.handle}`}
        >
          <GatsbyImage
            className="product-image"
            image={item.product.document.data.thumbnail.gatsbyImageData}
            alt={
              item.product.document.data.thumbnail.alt !== null
                ? item.product.document.data.thumbnail.alt
                : ``
            }
          />
        </Link>
      </div>

      <div className="text-container">
        <h4 className="title uppercase gotham-light">
          {item.product.document.data.title.text}
        </h4>

        <p className="price gotham-light">
          {item.product.document.data.price_on_demand === false ? (
            <p className="price">
              {new Intl.NumberFormat("en-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                item.product.document.data.shopify_product.variants[0].price
              )}
            </p>
          ) : (
            <p className="price">
              {currentLanguage === `en`
                ? `Price on demand`
                : `Prix sur demande`}
            </p>
          )}
        </p>
      </div>
    </div>
  );
};

export const ProductCarousel = ({ content, index, currentLanguage }) => {
  const [itemWidth, setItemWidth] = useState(``);
  const gallery = useRef();
  const [currentSection, setCurrentSection] = useState(1);

  const filteredProducts = content.items
    .filter((item) => item.product.document !== null)
    .filter((item) => item.product.document.data !== undefined)
    .filter((item) => item.product.document.data.shopify_product !== null);

  if (filteredProducts.length > 0) {
    const products = content.items
      .filter((item) => item.product.document !== null)
      .filter((item) => item.product.document.data !== undefined)
      .filter((item) => item.product.document.data.shopify_product !== null)
      .map((item, innerIndex) => (
        <SingleProduct
          key={`single_page_product_row_carousel_${innerIndex}_row_${index}_${content.id}`}
          item={item}
          setItemWidth={setItemWidth}
          currentLanguage={currentLanguage}
        />
      ));

    const numberOfSlideSections = Math.ceil(products.length / 3);

    const scrollToNextPage = () => {
      gallery.current.scrollBy({
        top: 0,
        left: itemWidth * 3,
        behavior: "smooth",
      });

      if (currentSection < numberOfSlideSections) {
        setCurrentSection(currentSection + 1);
      }
    };
    const scrollToPrevPage = () => {
      gallery.current.scrollBy({
        top: 0,
        left: -itemWidth * 3,
        behavior: "smooth",
      });

      if (currentSection <= numberOfSlideSections && currentSection > 1) {
        setCurrentSection(currentSection - 1);
      }
    };

    return (
      <ProductCarouselContainer className="product-row-carousel module">
        {/* module */}
        <div className="title-bar">
          <div className="carousel-title">
            <PrismicRichText
              field={content.primary.product_row_title.richText}
              components={{
                heading3: ({ children, index }) => (
                  <p
                    className="tag uppercase gotham-bold"
                    key={`single_product_row_p_${index}`}
                  >
                    {children}
                  </p>
                ),
              }}
            />
          </div>

          <div className="navigation">
            <button onClick={() => scrollToPrevPage()}>
              <ArrowPrev />
            </button>

            <p className="gotham-bold tag">{currentSection}</p>
            <p className="gotham-bold tag spacer">—</p>
            <p className="gotham-bold tag">{numberOfSlideSections}</p>

            <button onClick={() => scrollToNextPage()}>
              <ArrowNext />
            </button>
          </div>
        </div>
        <div className="slide-container" ref={gallery}>
          {products}
        </div>
      </ProductCarouselContainer>
    );
  } else {
    return null;
  }
};
