import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const LegalNoticeAddressContainer = styled.div`
  max-width: 740px;
  width: 100%;

  &.module {
    margin: 0;
  }

  & .content-container {
    padding: 50px;
    background-color: #fff;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;

    & .column-left,
    & .column-right {
      & p {
        font-size: 18px;
        line-height: 30px;

        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 800px) {
    & .content-container {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      padding: 25px;

      & .column-left,
      & .column-right {
        & p {
          font-size: 14px;
          line-height: 24px;
        }
      }

      & .column-left {
        margin: 0 0 1em 0;
      }
    }
  }
`;

export const LegalNoticeAddress = ({ content }) => {
  return (
    <LegalNoticeAddressContainer className="module legal-notice-address">
      <div className="content-container">
        <div className="column-left">
          <PrismicRichText field={content.primary.text_column_left.richText} />
        </div>
        <div className="column-right">
          <PrismicRichText field={content.primary.text_column_right.richText} />
        </div>
      </div>
    </LegalNoticeAddressContainer>
  );
};
