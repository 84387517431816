import React from "react";

export const BagIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2977 6.66667V5.55556C14.2977 2.5 11.8326 0 8.81964 0C5.80669 0 3.34154 2.5 3.34154 5.55556V6.66667H0.0546875V20H17.5846V6.66667H14.2977ZM4.43716 5.55556C4.43716 3.11111 6.40928 1.11111 8.81964 1.11111C11.23 1.11111 13.2021 3.11111 13.2021 5.55556V6.66667H4.43716V5.55556ZM16.489 18.8889H1.15031V7.77778H3.34154V10H4.43716V7.77778H13.2021V10H14.2977V7.77778H16.489V18.8889Z"
      fill="#09184F"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.2 7.0842C1.2 3.81864 3.77058 1.2 6.90673 1.2C10.0429 1.2 12.6135 3.81864 12.6135 7.0842C12.6135 10.3498 10.0429 12.9684 6.90673 12.9684C3.77058 12.9684 1.2 10.3498 1.2 7.0842ZM6.90673 0C3.07666 0 0 3.18751 0 7.0842C0 10.9809 3.07666 14.1684 6.90673 14.1684C8.35579 14.1684 9.69701 13.7121 10.8046 12.9338L14.7769 17.0185L15.1952 17.4486L16.0554 16.612L15.6371 16.1818L11.7254 12.1595C13.0151 10.8702 13.8135 9.0692 13.8135 7.0842C13.8135 3.18751 10.7368 0 6.90673 0Z"
      fill="#09184F"
    />
  </svg>
);

export const MenuIcon = () => (
  <svg
    width="21"
    height="12"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.585938 1.2H20.0637V0H0.585938V1.2ZM0.585938 11.2H20.0637V10H0.585938V11.2Z"
      fill="#09184F"
    />
  </svg>
);

export const CloseMenuIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21376 8.91884L13.437 15.1421L14.2855 14.2935L8.06229 8.07031L14.2841 1.84853L13.4355 1L7.21376 7.22178L0.850481 0.858503L0.00195312 1.70703L6.36523 8.07031L0.00051713 14.435L0.849045 15.2836L7.21376 8.91884Z"
      fill="#09184F"
    />
  </svg>
);

export const BackIcon = () => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.43359 0L0 7L6.43503 14.1421L7.32654 13.3388L1.62247 7.00802L7.31711 0.81203L6.43359 0Z"
      fill="#09184F"
    />
  </svg>
);
