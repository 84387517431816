import React, { useEffect, createContext, useContext, useState } from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";

// Context
import { CurrentLanguage } from "./current-language";

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `rinck_shopify_checkout_id`;

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [currentLanguage] = useContext(CurrentLanguage);
  const [client, setClient] = useState(null);
  const [checkout, setCheckout] = useState({ lineItems: [] });
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize Shopify client
  useEffect(() => {
    const newClient = Client.buildClient(
      {
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
        language: currentLanguage === `en` ? `en` : `fr-FR`,
      },
      fetch
    );

    setClient(newClient);
  }, [currentLanguage]);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }
    setCheckout(checkout);
  };

  useEffect(() => {
    let isMounted = true;

    const initializeCheckout = async () => {
      if (!client || !isMounted) return;

      try {
        setLoading(true);

        // Get existing checkout ID from localStorage
        const existingCheckoutID = isBrowser
          ? localStorage.getItem(localStorageKey)
          : null;

        let checkoutData;

        if (existingCheckoutID) {
          try {
            const existingCheckout = await client.checkout.fetch(
              existingCheckoutID
            );
            const checkoutLanguage = existingCheckout?.customAttributes?.find(
              (attr) => attr.key === "language"
            )?.value;

            if (!existingCheckout.completedAt) {
              if (checkoutLanguage !== currentLanguage) {
                // Create a new checkout and transfer line items
                const newCheckout = await client.checkout.create({
                  customAttributes: [
                    { key: "language", value: currentLanguage },
                  ],
                });
                if (existingCheckout.lineItems.length > 0) {
                  const lineItemsToAdd = existingCheckout.lineItems.map(
                    (item) => ({
                      variantId: item.variant.id,
                      quantity: item.quantity,
                    })
                  );
                  checkoutData = await client.checkout.addLineItems(
                    newCheckout.id,
                    lineItemsToAdd
                  );
                } else {
                  checkoutData = newCheckout;
                }
              } else {
                checkoutData = existingCheckout;
              }
            }
          } catch (e) {
            console.log("Error fetching existing checkout:", e);
            localStorage.removeItem(localStorageKey);
          }
        }

        // If no existing checkout or it was completed, create a new one
        if (!checkoutData) {
          const newCheckout = await client.checkout.create({
            customAttributes: [{ key: "language", value: currentLanguage }],
          });
          checkoutData = newCheckout;
        }

        if (isMounted) {
          setCheckoutItem(checkoutData);
          setIsInitialized(true);
        }
      } catch (error) {
        console.error("Checkout initialization error:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    initializeCheckout();

    return () => {
      isMounted = false;
    };
  }, [client, currentLanguage]);

  const addVariantToCart = async (variantId, quantity) => {
    if (!isInitialized) {
      console.error("Cannot add to cart: store not initialized");
      return;
    }

    if (!client || !checkout?.id) {
      console.error("Cannot add to cart: client or checkout not available");
      return;
    }

    setLoading(true);

    try {
      const res = await client.checkout.addLineItems(checkout.id, [
        {
          variantId,
          quantity: parseInt(quantity, 10),
        },
      ]);

      setCheckout(res);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
      return res;
    } catch (error) {
      console.error("Error adding item to cart:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const removeLineItem = async (checkoutID, lineItemID) => {
    if (!isInitialized) return;

    setLoading(true);

    try {
      const res = await client.checkout.removeLineItems(checkoutID, [
        lineItemID,
      ]);
      setCheckout(res);
      return res;
    } catch (error) {
      console.error("Error removing item from cart:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateLineItem = async (checkoutID, lineItemID, quantity) => {
    if (!isInitialized) return;

    setLoading(true);

    try {
      const res = await client.checkout.updateLineItems(checkoutID, [
        {
          id: lineItemID,
          quantity: parseInt(quantity, 10),
        },
      ]);
      setCheckout(res);
      return res;
    } catch (error) {
      console.error("Error updating cart item:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const setValue = (value) => {
    if (isBrowser) {
      localStorage.setItem("rinck_customerAccessToken", JSON.stringify(value));
    }
  };

  const getCustomerAccessToken = () => {
    if (isBrowser) {
      return JSON.parse(localStorage.getItem("rinck_customerAccessToken"));
    }
    return null;
  };

  const defaultValues = {
    client,
    loading,
    isInitialized,
    checkout,
    customerAccessToken: getCustomerAccessToken(),
    didJustAddToCart,
    setValue,
    addVariantToCart,
    removeLineItem,
    updateLineItem,
  };

  return (
    <StoreContext.Provider value={defaultValues}>
      {children}
    </StoreContext.Provider>
  );
};
