import React from "react";

export const CloseIcon = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.27727"
      y1="19.1659"
      x2="19.1497"
      y2="1.29354"
      stroke={fill !== undefined ? fill : `#09184F`}
      strokeWidth="2"
    />
    <line
      x1="0.707107"
      y1="1.29289"
      x2="18.5795"
      y2="19.1653"
      stroke={fill !== undefined ? fill : `#09184F`}
      strokeWidth="2"
    />
  </svg>
);
