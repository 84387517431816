import React from "react";
import { Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const ProductRowContainer = styled.div`
  & .title {
    margin: 0 0 40px 0;
  }

  & .products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 90px;

    @media (max-width: 1445px) {
      grid-column-gap: 40px;
    }

    @media (max-width: 999px) {
      grid-column-gap: 30px;
    }

    @media (max-width: 800px) {
      display: unset;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }

    & .single-product {
      & img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }

      & .text-container {
        margin: 18px 0 0 0;

        & h4,
        & p {
          font-size: 14px;
          line-height: 25px;

          margin: 0;
        }

        & .price {
          margin: 6px 0 0 0;
        }
      }

      @media (max-width: 800px) {
        margin: 0 0 50px 0;

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
`;

export const ProductRow = ({ content, index, currentLanguage }) => {
  const products = content.items
    .filter((item) => item.product.document !== null)
    .filter((item) => item.product.document.data !== undefined)
    .filter((item) => item.product.document.data.shopify_product !== null)
    .map((item, innerIndex) => (
      <div
        className="single-product"
        key={`single_page_product_${innerIndex}_row_${index}`}
      >
        <div className="image-container">
          <Link
            to={`/${currentLanguage}/products/${item.product.document.data.shopify_product.handle}/`}
          >
            <GatsbyImage
              className="product-image"
              image={item.product.document.data.thumbnail.gatsbyImageData}
              alt={
                item.product.document.data.thumbnail.alt !== null
                  ? item.product.document.data.thumbnail.alt
                  : ``
              }
            />
          </Link>
        </div>

        <div className="text-container">
          <h4 className="title uppercase gotham-light">
            {item.product.document.data.title.text}
          </h4>

          <p className="price gotham-light">
            {item.product.document.data.price_on_demand === false ? (
              <p className="price">
                {new Intl.NumberFormat("en-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(
                  item.product.document.data.shopify_product.variants[0].price
                )}
              </p>
            ) : (
              <p className="price">
                {currentLanguage === `en`
                  ? `Price on demand`
                  : `Prix sur demande`}
              </p>
            )}
          </p>
        </div>
      </div>
    ));
  return (
    <ProductRowContainer className="module product-row">
      <div className="title">
        <PrismicRichText
          field={content.primary.product_row_title.richText}
          components={{
            heading3: ({ children }) => (
              <h3 className="tag gotham-bold uppercase">{children}</h3>
            ),
          }}
        />
      </div>

      <div className="products">{products}</div>
    </ProductRowContainer>
  );
};
