import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";

// Components
import { MailchimpForm } from "../forms/form";

const NewsletterContainer = styled.div`
  background-color: ${(props) => props.bgColor};

  padding: 100px 40px;
  margin: 0 0 150px 0;

  @media (max-width: 800px) {
    padding: 90px 25px;
  }

  & .newsletter-inner-container {
    color: #f9f9f9;

    & p {
      font-size: 60px;
      line-height: 65px;

      margin: 0;
      max-width: 1150px;

      @media (max-width: 999px) {
        font-size: 40px;
        line-height: 50px;
      }
    }
  }
`;

export const Newsletter = ({
  newsletterBgColor,
  newsletterEmailPlaceholder,
  newsletterText,
  currentLanguage,
}) => {
  return (
    <NewsletterContainer bgColor={newsletterBgColor}>
      <div className="newsletter-inner-container">
        <div className="text classico">
          <PrismicRichText field={newsletterText} />
        </div>

        <div className="form-container">
          <MailchimpForm
            currentLanguage={currentLanguage}
            formName={`newsletter`}
            placeholder={newsletterEmailPlaceholder}
            color={`#fff`}
          />
        </div>
      </div>
    </NewsletterContainer>
  );
};
