import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyPressEvent } from "react-use";

// Slideshow
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

// Icons
import { ArrowNext, ArrowPrev, Separator } from "../icons/arrows";
import { CloseIcon } from "../icons/close-icon";

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  background-color: #efefef;

  z-index: 100;
  height: 100%;
  width: 100%;

  @media (max-width: 900px) {
    align-items: flex-start;
    overflow-y: scroll;
  }

  & .slideshow-container {
    position: relative;

    width: 100%;
    height: calc(${(props) => props.height}px - 60px - 50px);
    padding: 0 50px;

    @media (max-width: 1445px) {
      padding: 0 40px;
    }

    @media (max-width: 999px) {
      padding: 0 30px;
    }

    @media (max-width: 900px) {
      height: auto;
      padding: 30px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;

      & .text-container {
        grid-column: 1 / 6;
        max-width: 415px;
        padding: 0 0 0 85px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        @media (max-width: 1200px) {
          padding: 0 0 0 55px;
        }

        @media (max-width: 999px) {
          max-width: 355px;
        }

        @media (max-width: 900px) {
          grid-column: 1 / 13;
          margin: 0 0 90px 0;
        }

        & .navigation {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;

          @media (max-width: 500px) {
            display: none;
          }

          & button {
            padding: 0;
            width: 25px;
            height: 11px;

            line-height: 1;

            &:first-of-type {
              text-align: left;
            }

            &:last-of-type {
              text-align: right;
            }

            & svg {
              height: 13px;
              width: auto;
            }
          }

          & svg {
            &.line {
              height: 11px;
              width: auto;

              margin: 0 5px;
            }
          }

          & p {
            font-feature-settings: "tnum";

            margin: 0;

            &:last-of-type {
              text-align: right;
            }

            &.spacer {
              text-align: center;
              width: 20px;
            }
          }
        }
      }
    }
  }

  & .single-image-container {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: calc(${(props) => props.height}px - 60px - 50px);

    margin: 0 0 0 auto;

    @media (max-width: 900px) {
      max-height: 100%;
    }

    // &.landscape {
    //   & .aspect-ratio-container {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //   }
    // }

    & img:not([aria-hidden="true"]) {
      border: 39px solid #fff;
      box-sizing: border-box;
      margin: 0 0 0 auto;

      max-height: calc(${(props) => props.height}px - 60px - 50px);

      object-fit: cover;
      object-position: right;

      width: fit-content;
      height: 100%;
      max-width: 100%;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }

      @media (max-width: 900px) {
        max-height: 100%;
      }
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: calc(${(props) => props.height}px - 60px - 50px);

  grid-column: 6 / 13;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div {
    position: relative;

    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    grid-column: 1 / 13;
    max-height: 100%;
  }
`;

const CloseLightbox = styled.button`
  position: absolute;
  top: 43px;
  left: 30px;

  z-index: 200;

  padding: 20px 20px 20px 0;

  &:hover {
    & svg {
      fill: #ff0000;
    }
  }

  @media (max-width: 900px) {
    padding: 0 20px 20px 0;
  }
`;

Modal.setAppElement("#___gatsby");

export const ProjectPopUp = ({
  isPopupOpen,
  setIsPopupOpen,
  activeProject,
}) => {
  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

  useEffect(() => {
    setTotalSlides(activeProject.data.images.length);
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", (event) => onEscape(event));
    }
  }, [isPopupOpen]);

  const onEscape = (event) => {
    if (event.keyCode === 27 && isPopupOpen === true) {
      document.body.style.overflow = "";
      setIsPopupOpen(false);
    }
  };

  // Keyboard navigation events for slideshow
  const goBack = () => {
    if (slideRef.current !== undefined && images.length > 1) {
      slideRef.current.goBack();
    }
  };

  const goNext = () => {
    if (slideRef.current !== undefined && images.length > 1) {
      slideRef.current.goNext();
    }
  };

  useKeyPressEvent("ArrowRight", goNext);
  useKeyPressEvent("ArrowLeft", goBack);

  const images = activeProject.data.images
    .filter((image) => image.image.fluid !== null)
    .map((image, index) => {
      return (
        <div
          className={`single-image-container ${ImageOrientation(image.image)}`}
          key={`single_page_item_slideshow_${index}`}
        >
          <AspectRatioImageContainer
            image={null}
            padding={ImageOrientation(image.image) === `portrait` ? 133 : 75}
          >
            <img
              className={ImageOrientation(image.image)}
              srcSet={image.image.fluid.srcSet}
              src={image.image.fluid.src}
              alt={image.image.alt}
            />
          </AspectRatioImageContainer>
        </div>
      );
    });

  return (
    <RemoveScroll>
      <Modal
        isOpen={isPopupOpen}
        portalClassName={`ReactModalPortal-gallery`}
        className="artwork-lighbox-modal"
        contentLabel={`Project Gallery`}
        shouldFocusAfterRender={true}
      >
        <LightboxContainer height={height}>
          <CloseLightbox onClick={() => setIsPopupOpen(false)}>
            <CloseIcon />
          </CloseLightbox>

          <div className="slideshow-container">
            <div className="lightbox-wrapper">
              <div className="text-container">
                <div className="inner-text-container">
                  <PrismicRichText
                    field={activeProject.data.title.richText}
                    components={{
                      heading1: ({ children, index }) => (
                        <h3 key={`single_h3_${index}`}>{children}</h3>
                      ),
                    }}
                  />
                  <PrismicRichText field={activeProject.data.text.richText} />
                </div>

                <div className="navigation">
                  {images.length > 1 && (
                    <button
                      aria-label="Previous Slide"
                      className={`previous`}
                      onClick={() => {
                        slideRef.current.goBack();
                      }}
                    >
                      <ArrowPrev />
                    </button>
                  )}

                  <p className="gotham-bold tag">{currentSlideNumber + 1}</p>
                  <p className="gotham-bold tag spacer">
                    <Separator />
                  </p>
                  <p className="gotham-bold tag">{totalSlides}</p>

                  {images.length > 1 && (
                    <button
                      aria-label="Next Slide"
                      className={`next`}
                      onClick={() => {
                        slideRef.current.goNext();
                      }}
                    >
                      <ArrowNext />
                    </button>
                  )}
                </div>
              </div>

              <GalleryContainer height={height}>
                <Fade
                  ref={slideRef}
                  arrows={false}
                  transitionDuration={0}
                  pauseOnHover={false}
                  autoplay={false}
                  infinite={true}
                  canSwipe={true}
                  defaultIndex={0}
                  onChange={(previous, next) => {
                    setCurrentSlideNumber(next);
                  }}
                >
                  {images}
                </Fade>
              </GalleryContainer>
            </div>
          </div>
        </LightboxContainer>
      </Modal>
    </RemoveScroll>
  );
};
