import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const UseFrenchMenuData = () => {
  const englishMenuData = useStaticQuery(graphql`
    {
      prismicMenu(lang: { eq: "fr-fr" }) {
        lang
        data {
          shop_menu_title {
            text
          }
          shop_page_link {
            url
          }
          activities_menu_title {
            text
          }
          activities_page_link {
            url
          }
          journal_menu_title {
            text
          }
          journal_page_link {
            url
          }
          featured_edits_link {
            document {
              ... on PrismicPage {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          body {
            ... on PrismicMenuDataBodyMenuItemWithSubpages {
              id
              slice_type
              primary {
                title {
                  text
                }
                page {
                  url
                  document {
                    ... on PrismicPage {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
              items {
                link {
                  document {
                    ... on PrismicCollection {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicActivity {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicActivityCategory {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicJournal {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }

                    ... on PrismicJournalCategory {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                  url
                }
              }
            }
            ... on PrismicMenuDataBodyMenuItem {
              id
              slice_type
              primary {
                page {
                  url
                  document {
                    ... on PrismicCollection {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          body1 {
            ... on PrismicMenuDataBody1MenuItemWithSubpages {
              id
              slice_type
              primary {
                title {
                  text
                }
                page {
                  url
                }
              }
              items {
                link {
                  document {
                    ... on PrismicCollection {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicActivity {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicActivityCategory {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicJournal {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }

                    ... on PrismicJournalCategory {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                  url
                }
              }
            }
          }
          body2 {
            ... on PrismicMenuDataBody2MenuItemWithSubpages {
              id
              slice_type
              primary {
                page {
                  url
                  document {
                    ... on PrismicCollection {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicActivity {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicActivityCategory {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicJournal {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }

                    ... on PrismicJournalCategory {
                      id
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return englishMenuData;
};
