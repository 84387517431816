import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useInView } from "react-intersection-observer";

// Context

import { HeaderColor } from "../context/header-color";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const LinkedSectionCoverContainer = styled.div`
  background-color: #efefef;

  &.module {
    padding: 80px 80px 65px 80px;
    margin: 0 0 80px 0;

    @media (max-width: 1445px) {
      padding: 50px 40px 65px 40px;
    }

    @media (max-width: 800px) {
      padding: 25px 0 0 0;
    }
  }

  & .image-container {
    grid-column: 1 / 7;
    padding: 0 25px 0 0;

    & img:not([aria-hidden="true"]) {
      border: 39px solid #fff;
      box-sizing: border-box;

      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }
    }
  }

  & .text-container {
    grid-column: 7 / 13;
    margin: 39px 0 0 0;
    padding: 0 0 0 25px;

    max-width: 600px;

    & .title-bar {
      display: flex;
      flex-direction: row;

      & p {
        margin: 0;
      }

      & .spacer {
        margin: 0 10px;
      }
    }

    & .title {
      margin: 20px 0 30px 0;

      & h1 {
        font-size: 70px;
        line-height: 70px;

        @media (max-width: 1445px) {
          font-size: 60px;
          line-height: 65px;
        }

        @media (max-width: 999px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }

    & .title,
    & .text,
    & .linked-sections-container {
      width: 100%;
    }

    & .linked-sections-container {
      margin: 60px 0 0 0;

      & ol {
        & li {
          margin: 0 0 5px 0;

          & h3 {
            display: grid;
            grid-template-columns: 40px 1fr;
            grid-column-gap: 15px;
            align-items: flex-start;

            & .line {
              display: block;
              width: 40px;
              margin: 15px 0 0 0;
              border-bottom: 1px solid #09184f;
            }

            & button {
              text-align: left;

              display: inline;
              width: fit-content;

              text-decoration: underline;
              text-decoration-color: transparent;
              text-underline-offset: 3px;
              text-decoration-thickness: 1px;

              transition: text-decoration-color 300ms;

              &:hover {
                text-decoration-color: #09184f;
              }
            }

            @media (max-width: 800px) {
              grid-template-columns: 30px 1fr;

              & .line {
                width: 30px;
              }

              & button {
                text-align: left;

                font-size: 19px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1445px) {
    & .text-container {
      grid-column: 7 / 13;
      padding: 39px 0;
      margin: 0 0 0 60px;
    }

    & .image-container {
      grid-column: 1 / 7;
      padding: 0;
    }
  }

  @media (max-width: 999px) {
  }

  @media (max-width: 800px) {
    & .text-container {
      grid-column: 1 / 7;
      order: 2;

      padding: 39px 25px;
      margin: 0;

      & .title {
        margin: 20px 0;
      }

      & .linked-sections-container {
        margin: 40px 0 0 0;
      }
    }

    & .image-container {
      grid-column: 1 / 7;
      order: 1;
      padding: 0 25px;
    }
  }
`;

export const LinkedSectionCover = ({
  title,
  text,
  image,
  linkedSections,
  index,
}) => {
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  const { ref, inView, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: [0.1],
  });

  useEffect(() => {
    if (entry !== undefined) {
      if (entry.boundingClientRect.top <= 79 && entry.isIntersecting) {
        setHeaderColor(`#efefef`);
      } else {
        if (headerColor !== `#fff`) {
          setHeaderColor(`#fff`);
        }
      }
    }
  }, [entry]);

  return (
    <LinkedSectionCoverContainer className="module activity grid-12" ref={ref}>
      <div className="image-container">
        {image.fluid !== null && (
          <AspectRatioImageContainer image={image}>
            <GatsbyImage
              image={image.gatsbyImageData}
              // src={image.fluid.src}
              alt={image.alt !== null ? image.alt : ``}
              fetchpriority={index <= 1 ? `high` : ``}
              loading={index <= 1 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>
        )}
      </div>

      <div className="text-container">
        <div className="title">
          <PrismicRichText
            field={title}
            components={{
              heading2: ({ children, index }) => (
                <h1 className="activity-title">{children}</h1>
              ),
            }}
          />
        </div>

        <div className="text">
          <PrismicRichText field={text} />
        </div>

        <div className="linked-sections-container">
          <ol>{linkedSections}</ol>
        </div>
      </div>
    </LinkedSectionCoverContainer>
  );
};
