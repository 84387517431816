import React from "react";

// Components
import { Search } from "./search-bar";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

const SearchIndex = ({ setActiveMenu, currentLanguage }) => {
  const data = UseSearchData();

  if (data !== null) {
    return (
      <Search
        index={data.index}
        store={data.store}
        setActiveMenu={setActiveMenu}
        currentLanguage={currentLanguage}
      />
    );
  } else {
    return null;
  }
};

export default SearchIndex;
