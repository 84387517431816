import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useWindowScroll, useWindowSize } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Components
import { SingleArticle } from "../components/modules/single-article";
import { DetermineModule } from "../components/utils/determine-module";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { PageColor } from "../components/context/page-color";
import { HeaderColor } from "../components/context/header-color";

const Page = styled.div``;

const Section = styled.section`
  padding: 0 50px;
  margin: 0 0 200px 0;

  border-bottom: 1px solid #09184f;

  & .first-article,
  & .remaining-articles {
    grid-column: span 6;
  }

  & .first-article {
    padding: 0 50px;
    margin: 0 0 200px 0;

    @media (max-width: 800px) {
      margin: 0 0 90px 0;
    }

    /* &.sticky { */
    position: relative;

    & #first-article-container {
      position: sticky;

      top: calc(
        ${(props) => props.height}px - ${(props) => props.heightCalculation}px -
          39px
      );

      @media (max-width: 999px) {
        top: calc(
          ${(props) => props.height}px - ${(props) => props.heightCalculation}px -
            27px
        );
      }
    }
    /* } */

    & .single-article {
      & .text-container {
        padding: 0 39px;

        @media (max-width: 800px) {
          padding: 0 25px;
        }
      }

      & img:not([aria-hidden="true"]) {
        border: 39px solid #efefef;
        box-sizing: border-box;

        width: 100%;
        height: 100%;

        @media (max-width: 999px) {
          border: 27px solid #efefef;
        }
      }
    }
  }

  & .remaining-articles {
    margin: 100px 0 200px 0;
  }

  @media (max-width: 1445px) {
    padding: 0 40px;

    & .grid-2 {
      grid-column-gap: 40px;
    }
  }

  @media (max-width: 999px) {
    padding: 0 30px;
    margin: 0 0 150px 0;

    &.grid-column-gap-50 {
      grid-column-gap: 20px !important;
    }

    & .first-article {
      grid-column: 2 / 9;
      padding: 0 25px;
    }

    & .remaining-articles {
      margin: 0 0 150px 0;
      grid-column: 10 / 13;

      &.grid-2 {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }

  @media (max-width: 800px) {
    margin: 0 0 100px 0;
    padding: 0;

    & .first-article {
      grid-column: span 6;
      padding: 0;

      & .single-article {
        max-width: 100%;
        margin: 0;
      }
    }

    & .remaining-articles {
      margin: 0 0 100px 0;

      &.grid-2 {
        grid-column: span 6;

        display: flex;
        flex-direction: row;

        max-width: 100%;

        overflow-x: scroll;
        padding: 0 25px;

        &::-webkit-scrollbar {
          display: none;
        }

        & .single-article {
          min-width: 50vw;
          margin: 0 40px 0 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    & .remaining-articles {
      &.grid-2 {
        & .single-article {
          min-width: 70vw;
          margin: 0 25px 0 0;
        }
      }
    }
  }
`;

const Journal = ({ data }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);
  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const { x, y } = useWindowScroll();
  const { width, height } = useWindowSize();

  // const [triggerPoint, setTriggerPoint] = useState(null);
  const [heightCalculation, setHeightCalculation] = useState(10);

  const firstArticle = data.prismicJournal.data.articles
    .filter((article) => article.article.document !== null)
    .filter((article, index) => index === 0)
    .map((article, index) => (
      <SingleArticle
        article={article.article.document}
        key={`single_journal_page_article_${index}_${article.article.id}`}
        currentLanguage={currentLanguage}
      />
    ));

  const remainingArticles = data.prismicJournal.data.articles
    .filter((article) => article.article.document !== null)
    .filter((article, index) => index >= 1)
    .map((article, index) => (
      <SingleArticle
        article={article.article.document}
        key={`single_journal_page_article_${index}_${article.article.id}`}
        currentLanguage={currentLanguage}
      />
    ));

  const content = data.prismicJournal.data.body.map((content, index) => (
    <DetermineModule
      content={content}
      index={index}
      key={`single_module_${index}_${data.prismicJournal.id}`}
      pageType={`journal`}
      currentLanguage={currentLanguage}
    />
  ));

  useEffect(() => {
    let firstArticleContainer = document.getElementById(
      `first-article-container`
    );
    let articleContainerDims = firstArticleContainer.getBoundingClientRect();
    setHeightCalculation(articleContainerDims.height);
  }, [height, width, setHeightCalculation]);

  return (
    <>
      <PageSeo
        title={data.prismicJournal.data.title.text}
        image={null}
        description={null}
        url={data.prismicJournal.url}
      />
      <Page className="page">
        <Section
          className="articles-container grid-12 grid-column-gap-50"
          height={height}
          heightCalculation={heightCalculation}
        >
          <div className={`first-article ${isSticky ? `sticky` : ``}`}>
            <div id="first-article-container">{firstArticle}</div>
          </div>

          <div className="remaining-articles grid-2">{remainingArticles}</div>
        </Section>

        {content}
      </Page>
    </>
  );
};

export default withPrismicPreview(Journal);

export const query = graphql`
  query JournalQuery($id: String, $lang: String) {
    prismicJournal(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          text
        }
        articles {
          article {
            document {
              ... on PrismicArticle {
                id
                url
                data {
                  title {
                    richText
                  }
                  excerpt_text {
                    richText
                  }
                  thumbnail {
                    alt
                    dimensions {
                      height
                      width
                    }
                    gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0.75)
                    fluid {
                      aspectRatio
                    }
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicJournalCategory {
                          id
                          url
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicJournalDataBodyBigPush {
            id
            slice_type
            primary {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH)
                        fluid {
                          aspectRatio
                        }
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              big_push_title {
                richText
              }
              call_to_action {
                richText
              }
            }
          }
          ... on PrismicJournalDataBodyCover {
            id
            slice_type
            primary {
              cover_image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              cover_text {
                richText
              }
              cover_title {
                richText
              }
              cover_call_to_action {
                richText
              }
              image_position
              link {
                url
              }
            }
          }
          ... on PrismicJournalDataBodyEdit {
            id
            slice_type
            primary {
              background_color
              journal_edit {
                type
                document {
                  ... on PrismicJournalEdit {
                    id
                    data {
                      title {
                        richText
                      }
                      articles {
                        article {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                              }
                            }
                            ... on PrismicArticle {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicJournalCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicActivity {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            ... on PrismicProject {
                              id
                              url
                              type
                              data {
                                title {
                                  richText
                                }
                                excerpt_text {
                                  richText
                                }
                                thumbnail {
                                  alt
                                  gatsbyImageData(aspectRatio: 0.75)
                                  fluid(
                                    imgixParams: { ar: "3:4", fit: "crop" }
                                  ) {
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                  dimensions {
                                    height
                                    width
                                  }
                                }
                                categories {
                                  category {
                                    document {
                                      ... on PrismicActivityCategory {
                                        id
                                        url
                                        data {
                                          title {
                                            text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicJournalDataBodyEditorialCover {
            id
            slice_type
            primary {
              editorial_credits {
                richText
              }
              editorial_text {
                richText
              }
              editorial_title {
                richText
              }
              image {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                fluid {
                  aspectRatio
                }
              }
              image_position
            }
          }
          ... on PrismicJournalDataBodyEditorialImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  aspectRatio
                }
                gatsbyImageData(layout: FULL_WIDTH)
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicJournalDataBodyVideo {
            id
            slice_type
            primary {
              video {
                type
                html
                embed_url
                provider_name
                id
              }
              video_size
            }
          }
          ... on PrismicJournalDataBodyEditorialImageWithText {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
              }
            }
          }
          ... on PrismicJournalDataBodyEditorialImages {
            id
            slice_type
            primary {
              editorial_image_left {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              editorial_image_right {
                dimensions {
                  height
                  width
                }
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 1000)
                fluid {
                  aspectRatio
                }
              }
              image_left_background
              image_right_background
            }
          }
          ... on PrismicJournalDataBodyEditorialNote {
            id
            slice_type
            primary {
              editorial_note_title
              editorial_note_text {
                richText
              }
            }
          }
          ... on PrismicJournalDataBodyEditorialQuote {
            id
            slice_type
            primary {
              editorial_quote {
                richText
              }
            }
          }
          ... on PrismicJournalDataBodyEditorialRow {
            id
            slice_type
            primary {
              editorial_row_title {
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      excerpt_text {
                        richText
                      }
                      thumbnail {
                        alt
                        dimensions {
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 800)
                      }
                      categories {
                        category {
                          document {
                            ... on PrismicJournalCategory {
                              id
                              url
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicJournalDataBodyEditorialText {
            id
            slice_type
            primary {
              editorial_text {
                richText
              }
            }
          }
          ... on PrismicJournalDataBodyProductRowCarousel {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicJournalDataBodyProductRow {
            id
            slice_type
            primary {
              product_row_title {
                richText
              }
            }
            items {
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 0.71)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
                url
                id
              }
            }
          }
          ... on PrismicJournalDataBodyProductCardLarge {
            id
            slice_type
            primary {
              orientation
              product {
                document {
                  ... on PrismicProduct {
                    id
                    url
                    data {
                      thumbnail {
                        gatsbyImageData(layout: FULL_WIDTH)
                        alt
                      }
                      title {
                        text
                      }
                      price_on_demand
                      shopify_product {
                        title
                        handle
                        id
                        variants {
                          price
                        }
                      }
                    }
                  }
                }
              }
              orientation
            }
          }
          ... on PrismicJournalDataBodyEditorialIntroduction {
            id
            slice_type
            primary {
              editorial_introduction_text {
                richText
              }
            }
          }
          ... on PrismicJournalDataBodyPushStory {
            id
            slice_type
            primary {
              background_color
              story {
                document {
                  ... on PrismicArticle {
                    id
                    url
                    data {
                      title {
                        richText
                      }
                      thumbnail {
                        alt
                        gatsbyImageData
                        fluid {
                          src
                          srcSet
                          aspectRatio
                        }
                        dimensions {
                          height
                          width
                        }
                      }
                      excerpt_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicJournalDataBodyTitleAndText {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
