import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const EditorialImageWithTextContainer = styled.div`
  & .image-container {
    grid-column: span 6;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    & .image-wrapper {
      max-width: 500px;
      width: 100%;

      margin: 0 auto;

      & img {
      }
    }
  }

  & .text-container {
    grid-column: span 6;

    & .text-wrapper {
      max-width: 780px;
      margin: 0 auto;

      & p {
        font-size: 18px;
        line-height: 30px;

        text-align: justify;
      }
    }
  }

  @media (max-width: 1445px) {
    & .image-container {
      grid-column: span 5;

      & .image-wrapper {
        max-width: 70%;
        width: 100%;
      }
    }

    & .text-container {
      grid-column: span 7;
    }
  }

  @media (max-width: 999px) {
    & .image-container {
      grid-column: span 4;

      & .image-wrapper {
        max-width: 90%;
      }
    }

    & .text-container {
      grid-column: span 8;
    }
  }

  @media (max-width: 800px) {
    & .image-container {
      grid-column: span 6;

      & .image-wrapper {
        max-width: 100%;
        padding: 0 25px;
      }
    }

    & .text-container {
      grid-column: span 6;
      margin: 70px 0 0 0;

      & .text-wrapper {
        & p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const EditorialImageWithText = ({ content, index }) => {
  return (
    <EditorialImageWithTextContainer className="module editorial-image-with-text grid-12">
      <div className="image-container">
        <div className="image-wrapper">
          {content.primary.image.fluid !== null && (
            <AspectRatioImageContainer image={content.primary.image}>
              <GatsbyImage
                image={content.primary.image.gatsbyImageData}
                // src={content.primary.image.fluid.src}
                alt={
                  content.primary.image.alt !== null
                    ? content.primary.image.alt
                    : ``
                }
                fetchpriority={index <= 1 ? `high` : ``}
                loading={index <= 1 ? `eager` : `lazy`}
              />
            </AspectRatioImageContainer>
          )}

          {content.primary.image.alt !== null && (
            <p className="caption uppercase">{content.primary.image.alt}</p>
          )}
        </div>
      </div>

      <div className="text-container">
        <div className="text-wrapper">
          {content.primary.text !== undefined && (
            <PrismicRichText field={content.primary.text.richText} />
          )}
        </div>
      </div>
    </EditorialImageWithTextContainer>
  );
};
