import React from "react";

export const Logo = () => (
  <svg
    width="133"
    height="38"
    viewBox="0 0 133 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M119.426 15.4371L132.489 0.851562H129.981L112.942 19.7969V0.851562H109.367V36.617H112.942V22.6873L117.547 17.538L129.835 36.617H132.998L119.426 15.4371Z"
      fill="#09184F"
    />
    <path
      d="M103.565 7.10666C99.7594 1.79954 94.8151 0.0385895 89.3497 0.00215615C81.1091 -0.143577 71.0508 7.10666 71.0508 19.1904C71.0508 31.2741 80.9394 37.5042 90.3918 37.5042C93.3129 37.5216 96.1831 36.7389 98.6926 35.2407C101.202 33.7424 103.256 31.5856 104.631 29.0031C104.631 29.0031 103.516 29.0031 102.728 29.0031C101.432 31.9299 96.3783 35.6583 90.5373 35.6583C84.6962 35.6583 77.0009 32.5614 77.0009 19.0932C77.0009 5.09068 83.8963 1.89669 89.5314 1.70238C91.8501 1.60329 94.1606 2.03902 96.2847 2.97599C98.4089 3.91296 100.29 5.32615 101.783 7.10666H103.565Z"
      fill="#09184F"
    />
    <path
      d="M65.7173 0.859375H63.7299V27.6258L44.304 0.859375H40.5352V36.6127H42.4983V5.93576L65.7173 37.9971V0.859375Z"
      fill="#09184F"
    />
    <path
      d="M32.4046 0.851562H28.3086V36.6048H32.4046V0.851562Z"
      fill="#09184F"
    />
    <path
      d="M21.1225 10.1517C21.1225 4.07943 16.5296 0.921875 9.2585 0.921875H0V36.6144H3.98697V19.0778H7.62251L18.6503 36.6144H21.8253C20.2257 33.8941 14.2877 24.47 10.9187 19.0778C16.3963 19.0778 21.1467 15.058 21.1467 10.0909L21.1225 10.1517ZM16.5902 10.1517C16.6292 11.9846 15.9644 13.7626 14.7331 15.1185C13.5019 16.4745 11.7981 17.3049 9.97349 17.4383H3.98697V3.80011C3.98697 2.39135 4.30205 2.36706 6.21677 2.36706C8.13148 2.36706 9.44027 2.36706 10.0704 2.36706C15.4995 2.99857 16.5902 6.64191 16.5902 10.1517Z"
      fill="#09184F"
    />
  </svg>
);
