import React from "react";
import { Link } from "gatsby";

export const DesktopJournalLinks = ({
  data,
  activeInnerMenu,
  setActiveInnerMenu,
  setActiveMenuHeight,
}) => {
  const journalLinks = data
    .filter((link) => link.primary.page.document !== null)
    .map((link, index) => {
      return (
        <li
          key={`single_journal_section_${index}`}
          className="classico single-link-section"
        >
          <Link to={`${link.primary.page.url}`}>
            {link.primary.page.document.data.title.text}
          </Link>
        </li>
      );
    });

  return journalLinks;
};
