import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const HiddenForms = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicProduct {
        nodes {
          lang
          data {
            body {
              ... on PrismicProductDataBodyCustomOrderForm {
                id
                slice_type
                items {
                  form_input_field
                  textarea
                }
              }
              ... on PrismicProductDataBodyRefundForm {
                id
                slice_type
                items {
                  form_input_field
                }
              }
            }
          }
        }
      }
      allPrismicPage {
        nodes {
          lang
          data {
            body {
              ... on PrismicPageDataBodyCustomOrderForm {
                id
                slice_type
                items {
                  form_input_field
                  textarea
                }
              }
              ... on PrismicPageDataBodyRefundForm {
                id
                slice_type
                items {
                  form_input_field
                }
              }
            }
          }
        }
      }
    }
  `);

  const filteredCustomOrderFormPageData = data.allPrismicPage.nodes
    .map((node) =>
      node.data.body.map((item) => {
        if (item.slice_type === `custom_order_form`) {
          return node;
        } else {
          return null;
        }
      })
    )
    .flat()
    .filter((item) => item !== null);

  const filteredCustomOrderFormProductData = data.allPrismicProduct.nodes
    .map((node) =>
      node.data.body.map((item) => {
        if (item.slice_type === `custom_order_form`) {
          return node;
        } else {
          return null;
        }
      })
    )
    .flat()
    .filter((item) => item !== null);

  const filteredCustomOrderFormData = [
    ...filteredCustomOrderFormPageData,
    ...filteredCustomOrderFormProductData,
  ];

  const hiddenCustomOrderFormFields = filteredCustomOrderFormData
    .map((outerContent, index) => {
      return outerContent.data.body.map((content, index) => {
        if (content.slice_type === "custom_order_form") {
          const fields = content.items.map((field, index) => {
            return (
              <input
                type="hidden"
                aria-required="true"
                required
                aria-label={field.form_input_field}
                id={field.form_input_field}
                name={`${field.form_input_field}`}
                placeholder={`${field.form_input_field}*`}
                key={`single_${content.id}_form_field_${index}`}
              />
            );
          });

          return (
            <form
              type="hidden"
              name={`custom-order-form-${
                outerContent.lang === `en-gb` ? `en` : `fr`
              }`}
              key={`single_form_${index}_${content.id}`}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input
                type="hidden"
                name="form-name"
                value={`custom-order-form-${
                  outerContent.lang === `en-gb` ? `en` : `fr`
                }`}
              />
              {fields}
            </form>
          );
        } else {
          return null;
        }
      });
    })
    .flat()
    .filter((item) => item !== null);

  const filteredRefundFormPageData = data.allPrismicPage.nodes
    .map((node) =>
      node.data.body.map((item) => {
        if (item.slice_type === `refund_form`) {
          return node;
        } else {
          return null;
        }
      })
    )
    .flat()
    .filter((item) => item !== null);

  const filteredRefundFormProductData = data.allPrismicProduct.nodes
    .map((node) =>
      node.data.body.map((item) => {
        if (item.slice_type === `refund_form`) {
          return node;
        } else {
          return null;
        }
      })
    )
    .flat()
    .filter((item) => item !== null);

  const filteredRefundFormData = [
    ...filteredRefundFormPageData,
    ...filteredRefundFormProductData,
  ];

  const hiddenRefundFormFields = filteredRefundFormData
    .map((outerContent, index) => {
      return outerContent.data.body.map((content, index) => {
        if (content.slice_type === "refund_form") {
          const fields = content.items.map((field, index) => {
            return (
              <input
                type="hidden"
                aria-required="true"
                required
                aria-label={field.form_input_field}
                id={field.form_input_field}
                name={`${field.form_input_field}`}
                placeholder={`${field.form_input_field}*`}
                key={`single_${content.id}_form_field_${index}`}
              />
            );
          });

          return (
            <form
              type="hidden"
              name={`refund-form-${
                outerContent.lang === `en-gb` ? `en` : `fr`
              }`}
              key={`single_form_${index}_${content.id}`}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input
                type="hidden"
                name="form-name"
                value={`refund-form-${
                  outerContent.lang === `en-gb` ? `en` : `fr`
                }`}
              />
              {fields}
            </form>
          );
        } else {
          return null;
        }
      });
    })
    .flat()
    .filter((item) => item !== null);

  return (
    <>
      {hiddenCustomOrderFormFields}
      {hiddenRefundFormFields}
    </>
  );
};
