import React from "react";

export const ArrowNext = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.85496 6.7325L0.997147 1.9988L2.39294 0.566406L7.26671 5.31564L7.28906 5.2932L8.70583 6.70486L2.23708 13.197L0.820312 11.7854L5.85496 6.7325Z"
      fill="#09184F"
    />
  </svg>
);

export const ArrowPrev = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01557 0.490234L0.316406 6.74439L1.68123 8.20633L1.70402 8.18505L6.40367 13.1081L7.85032 11.7271L3.16605 6.82014L8.38039 1.95217L7.01557 0.490234Z"
      fill="#09184F"
    />
  </svg>
);

export const Separator = () => (
  <svg
    width="2"
    height="12"
    viewBox="0 0 2 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="line"
  >
    <line
      x1="1"
      y1="11.2031"
      x2="1"
      y2="0.000870705"
      stroke="#09184F"
      strokeWidth="2"
    />
  </svg>
);
