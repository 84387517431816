import React from "react";
import Select from "react-select";

// Components
import { ProjectPopUp } from "../project/project-popup";

export const ListProjects = ({
  projects,
  projectsFilter,
  setProjectsFilter,
  setActiveProject,
  activeProject,
  setIsPopupOpen,
  isPopupOpen,
  showFilters,
  projectFilterData,
}) => {
  const options = [
    { value: "all", label: "All Projects" },
    ...projectFilterData,
  ];

  return (
    <>
      {showFilters !== false && (
        <div className="dropdown-container">
          <Select
            className="project-filter-select"
            classNamePrefix="project-filter"
            defaultValue={projectsFilter}
            value={projectsFilter}
            options={options}
            onChange={setProjectsFilter}
            placeholder={`All Projects`}
            isSearchable={false}
            isMulti={false}
          />
        </div>
      )}

      <section className="content-container grid-6">{projects}</section>

      {/* <section className="view-more-articles-container">
        <button className="uppercase small">View 4 More Projects</button>
      </section> */}

      {isPopupOpen && (
        <ProjectPopUp
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          activeProject={activeProject}
        />
      )}
    </>
  );
};
