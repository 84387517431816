import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Utils
import kebabCase from "lodash.kebabcase";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const EditorialImageWithTextContainer = styled.div`
  align-items: center;

  & .image-container {
    grid-column: span 6;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    & .image-wrapper {
      max-width: 500px;
      width: 100%;

      margin: 0 auto;

      & img {
      }
    }
  }

  & .text-container {
    grid-column: span 6;

    & .text-wrapper {
      max-width: 780px;
      margin: 0 auto;

      & p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  @media (max-width: 1445px) {
    & .image-container {
      grid-column: span 5;

      & .image-wrapper {
        max-width: 70%;
        width: 100%;
      }
    }

    & .text-container {
      grid-column: span 7;
    }
  }

  @media (max-width: 999px) {
    & .image-container {
      grid-column: span 4;

      & .image-wrapper {
        max-width: 90%;
      }
    }

    & .text-container {
      grid-column: span 8;
    }
  }

  @media (max-width: 800px) {
    & .image-container {
      grid-column: span 6;

      & .image-wrapper {
        max-width: 100%;
        padding: 0 25px;
        margin: 0 0 70px 0;
      }
    }

    & .text-container {
      grid-column: span 6;

      & .text-wrapper {
        & p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const ActivityLinkedSection = ({ content, index }) => {
  return (
    <EditorialImageWithTextContainer
      className="module editorial-image-with-text grid-12"
      id={`linked-section-${kebabCase(content.primary.link_title.text)}`}
    >
      <div className="image-container">
        {content.primary.link_image.fluid !== null && (
          <div className="image-wrapper">
            <AspectRatioImageContainer image={content.primary.link_image}>
              <GatsbyImage
                image={content.primary.link_image.gatsbyImageData}
                alt={
                  content.primary.link_image.alt !== null
                    ? content.primary.link_image.alt
                    : ``
                }
                fetchpriority={index <= 1 ? `high` : ``}
                loading={index <= 1 ? `eager` : `lazy`}
              />
            </AspectRatioImageContainer>

            {content.primary.link_image.alt !== null && (
              <p className="caption uppercase">
                {content.primary.link_image.alt}
              </p>
            )}
          </div>
        )}
      </div>

      <div className="text-container">
        <div className="text-wrapper">
          <PrismicRichText field={content.primary.link_title.richText} />

          <PrismicRichText field={content.primary.link_text.richText} />
        </div>
      </div>
    </EditorialImageWithTextContainer>
  );
};
