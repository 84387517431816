exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-account-catalogues-js": () => import("./../../../src/pages/en/account/catalogues.js" /* webpackChunkName: "component---src-pages-en-account-catalogues-js" */),
  "component---src-pages-en-account-login-js": () => import("./../../../src/pages/en/account/login.js" /* webpackChunkName: "component---src-pages-en-account-login-js" */),
  "component---src-pages-en-account-logout-js": () => import("./../../../src/pages/en/account/logout.js" /* webpackChunkName: "component---src-pages-en-account-logout-js" */),
  "component---src-pages-en-account-order-history-js": () => import("./../../../src/pages/en/account/order-history.js" /* webpackChunkName: "component---src-pages-en-account-order-history-js" */),
  "component---src-pages-en-account-press-js": () => import("./../../../src/pages/en/account/press.js" /* webpackChunkName: "component---src-pages-en-account-press-js" */),
  "component---src-pages-en-cart-js": () => import("./../../../src/pages/en/cart.js" /* webpackChunkName: "component---src-pages-en-cart-js" */),
  "component---src-pages-en-reset-password-js": () => import("./../../../src/pages/en/reset-password.js" /* webpackChunkName: "component---src-pages-en-reset-password-js" */),
  "component---src-pages-fr-account-catalogues-js": () => import("./../../../src/pages/fr/account/catalogues.js" /* webpackChunkName: "component---src-pages-fr-account-catalogues-js" */),
  "component---src-pages-fr-account-login-js": () => import("./../../../src/pages/fr/account/login.js" /* webpackChunkName: "component---src-pages-fr-account-login-js" */),
  "component---src-pages-fr-account-logout-js": () => import("./../../../src/pages/fr/account/logout.js" /* webpackChunkName: "component---src-pages-fr-account-logout-js" */),
  "component---src-pages-fr-account-order-history-js": () => import("./../../../src/pages/fr/account/order-history.js" /* webpackChunkName: "component---src-pages-fr-account-order-history-js" */),
  "component---src-pages-fr-account-press-js": () => import("./../../../src/pages/fr/account/press.js" /* webpackChunkName: "component---src-pages-fr-account-press-js" */),
  "component---src-pages-fr-cart-js": () => import("./../../../src/pages/fr/cart.js" /* webpackChunkName: "component---src-pages-fr-cart-js" */),
  "component---src-pages-fr-reset-password-js": () => import("./../../../src/pages/fr/reset-password.js" /* webpackChunkName: "component---src-pages-fr-reset-password-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-activities-category-js": () => import("./../../../src/templates/activities/category.js" /* webpackChunkName: "component---src-templates-activities-category-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-en-product-js": () => import("./../../../src/templates/en/product.js" /* webpackChunkName: "component---src-templates-en-product-js" */),
  "component---src-templates-fr-product-js": () => import("./../../../src/templates/fr/product.js" /* webpackChunkName: "component---src-templates-fr-product-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-join-us-js": () => import("./../../../src/templates/join-us.js" /* webpackChunkName: "component---src-templates-join-us-js" */),
  "component---src-templates-journal-category-js": () => import("./../../../src/templates/journal/category.js" /* webpackChunkName: "component---src-templates-journal-category-js" */),
  "component---src-templates-journal-js": () => import("./../../../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

