import React, { useState, useEffect, useContext } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import "scroll-behavior-polyfill";
import { usePrevious } from "react-use";
import ScrollToTop from "react-scroll-to-top";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { Cookies } from "../cookies/cookies";

// Context
import { CurrentLanguage } from "../context/current-language";
import { HeaderColor } from "../context/header-color";
import { PageColor } from "../context/page-color";
import { PageContext } from "../context/page-context";

const Container = styled.div`
  margin: 79px 0 0 0;
  position: relative;

  &.project-page {
    margin: 0;
  }
`;

const Layout = ({ children }) => {
  const [headerColor, setHeaderColor] = useState(`#fff`);
  const [pageColor, setPageColor] = useState(`#fff`);
  const [pageContext, setPageContext] = useState({ isMenuOpen: false });
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  const prevHeaderColor = usePrevious(headerColor);

  useEffect(() => {
    document.body.style.backgroundColor = pageColor;
  }, [pageColor]);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PageContext.Provider value={[pageContext, setPageContext]}>
      <PageColor.Provider value={[pageColor, setPageColor]}>
        <HeaderColor.Provider value={[headerColor, setHeaderColor]}>
          <Location>
            {({ location }) => {
              return (
                <Container
                  location={location.pathname}
                  className={
                    location.pathname.includes(`/project/`)
                      ? `project-page`
                      : ``
                  }
                >
                  <Normalize />

                  <App
                    location={location}
                    children={children}
                    currentLanguage={currentLanguage}
                    setCurrentLanguage={setCurrentLanguage}
                    headerColor={headerColor}
                    setHeaderColor={setHeaderColor}
                    prevHeaderColor={prevHeaderColor}
                    setPageContext={setPageContext}
                    pageColor={pageColor}
                  />
                  <ScrollToTop
                    smooth
                    top={80}
                    width={20}
                    height={20}
                    color={`#09184f`}
                  />
                  <Cookies
                    location={location}
                    currentLanguage={currentLanguage}
                  />

                  <GlobalStyles />
                </Container>
              );
            }}
          </Location>
        </HeaderColor.Provider>
      </PageColor.Provider>
    </PageContext.Provider>
  );
};

export default Layout;
