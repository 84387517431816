import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

// Utils
import { encodeForm } from "../utils/encode-form";

const CustomOrderFormContainer = styled.div`
  & form {
    width: 100%;
    max-width: 680px;
    padding: 50px;

    background-color: #fff;

    & .form-fields-container {
      & input,
      & textarea {
        display: block;
        width: calc(100% - 20px - 20px);

        margin: 0 0 20px 0;
        padding: 16px 20px;

        border: 1px solid #09184f;

        ::-webkit-input-placeholder {
          color: #09184f;
        }

        ::-moz-placeholder {
          color: #09184f;
        }

        :-ms-input-placeholder {
          color: #09184f;
        }

        :-moz-placeholder {
          color: #09184f;
        }
      }

      margin: 0 0 50px 0;
    }

    & .button-container {
      & button {
        display: block;
        width: 260px;
        height: 50px;

        margin: 0 0 0 auto;

        font-size: 12px;

        background: #09184f;
        color: #fff;

        text-transform: uppercase;

        @media (max-width: 500px) {
          width: 100%;
        }

        text-decoration: underline;
        text-decoration-color: transparent;
        text-underline-offset: 0.2em;

        transition: text-decoration-color 300ms;

        &:hover {
          text-decoration-color: #fff;
        }
      }
    }

    & .message-container {
      margin-top: 20px;
      color: #09184f;
    }

    & .error-message {
      color: red;
      font-size: 14px;
      margin-top: -15px;
      margin-bottom: 15px;
    }
  }
`;

export const EnglishCustomOrderForm = ({ content, currentLanguage }) => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const [formMessage, setFormMessage] = useState(
    currentLanguage === `en` ? `Submit` : `Envoyer`
  );

  const onSubmit = (data, e) => {
    e.preventDefault();

    // Check if all fields are empty
    const isFormEmpty = Object.values(data).every(
      (value) => value.trim() === ""
    );

    if (isFormEmpty) {
      setFormMessage(
        currentLanguage === `en` ? `Form is empty` : `Le formulaire est vide`
      );
      return;
    }

    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodeForm({
        "form-name": `custom-order-form-en`,
        subject: `Custom Order Form Submission`,
        ...data,
      }),
    })
      .then(() => {
        reset();
        setFormMessage(currentLanguage === `en` ? `Submitted` : `Envoyé`);
      })
      .catch((err) => {
        console.error(err);
        setFormMessage(currentLanguage === `en` ? `Error` : `Erreur`);
      });
  };

  useEffect(() => {
    if (formMessage !== `Submit` && formMessage !== `Envoyer`) {
      const closeForm = setTimeout(() => {
        setFormMessage(currentLanguage === `en` ? `Submit` : `Envoyer`);
      }, 10000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  const fields = content.items.map((field, index) => {
    if (field.textarea === true) {
      return (
        <div key={`single_${content.id}_form_field_${index}`}>
          <textarea
            aria-required={field.required === true ? `true` : `false`}
            aria-label={field.form_input_field}
            id={field.form_input_field}
            name={`${field.form_input_field}`}
            placeholder={`${field.form_input_field} ${
              field.required === true ? `*` : ``
            }`}
            rows="20"
            ref={register({
              required:
                field.required === true ? "This field is required" : false,
            })}
          />
          {errors[field.form_input_field] && (
            <p className="error-message">
              {errors[field.form_input_field].message}
            </p>
          )}
        </div>
      );
    } else {
      return (
        <div key={`single_${content.id}_form_field_${index}`}>
          <input
            type="text"
            aria-required={field.required === true ? `true` : `false`}
            aria-label={field.form_input_field}
            id={field.form_input_field}
            name={`${field.form_input_field}`}
            placeholder={`${field.form_input_field} ${
              field.required === true ? `*` : ``
            }`}
            ref={register({
              required:
                field.required === true ? "This field is required" : false,
            })}
          />
          {errors[field.form_input_field] && (
            <p className="error-message">
              {errors[field.form_input_field].message}
            </p>
          )}
        </div>
      );
    }
  });

  return (
    <CustomOrderFormContainer className="module custom-order-form">
      <form
        name={`custom-order-form-en`}
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={`custom-order-form-en`} />
        <input type="hidden" name="bot-field" />

        <div className="form-fields-container">{fields}</div>
        <div className="button-container">
          <button
            type="submit"
            disabled={formMessage !== `Submit` && formMessage !== `Envoyer`}
          >
            {formMessage}
          </button>
        </div>
        <div className="message-container">
          {formMessage !== `Submit` && formMessage !== `Envoyer` && (
            <p>{formMessage}</p>
          )}
        </div>
      </form>
    </CustomOrderFormContainer>
  );
};
