import React from "react";
import styled from "styled-components";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { HiddenForms } from "./hidden-forms";

const Main = styled.main`
  flex: 1;

  & .page {
    margin: 130px 0 0 0;

    @media (max-width: 800px) {
      margin: 79px 0 0 0;
    }
  }

  & .grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    &.grid-column-gap-80 {
      grid-column-gap: 80px;

      @media (max-width: 800px) {
        grid-column-gap: 20px;
      }
    }

    &.grid-column-gap-50 {
      grid-column-gap: 50px;

      @media (max-width: 800px) {
        grid-column-gap: 20px;
      }
    }

    &.grid-row-gap-90 {
      grid-row-gap: 90px;

      @media (max-width: 999px) {
        grid-column-gap: 80px;
      }

      @media (max-width: 800px) {
        grid-column-gap: 50px;
      }
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  & .grid-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 200px;
  }

  & .grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 50px;

    @media (max-width: 999px) {
      grid-column-gap: 20px;
    }
  }

  & .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 100px;
  }

  & .module {
    margin: 0 0 150px 0;
    padding: 0 50px;

    &.padding-0 {
      padding: 0;
    }

    &.padding-inherit {
      padding: inherit;
    }

    @media (max-width: 1445px) {
      padding: 0 40px;
    }

    @media (max-width: 999px) {
      padding: 0 30px;
    }

    @media (max-width: 800px) {
      margin: 0 0 100px 0;
      padding: 0 25px;
    }
  }
`;

export const App = ({
  location,
  children,
  currentLanguage,
  setCurrentLanguage,
  headerColor,
  setHeaderColor,
  prevHeaderColor,
  setPageContext,
  pageColor,
}) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 870px)");
  return (
    <>
      {!location.pathname.includes(`/project/`) && (
        <Header
          location={location}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          isDesktop={isDesktop}
          headerColor={headerColor}
          setHeaderColor={setHeaderColor}
          prevHeaderColor={prevHeaderColor}
          setPageContext={setPageContext}
          pageColor={pageColor}
        />
      )}

      <Main>{children}</Main>

      {!location.pathname.includes(`/project/`) && (
        <Footer
          location={location}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />
      )}

      <HiddenForms />
    </>
  );
};
