import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const EditorialImageContainer = styled.div`
  & .image-container {
    padding: 0 60px;

    @media (max-width: 800px) {
      padding: 0;
    }

    &.image-portrait {
      grid-column: 4 / 10;

      @media (max-width: 999px) {
        grid-column: 3 / 11;
      }

      @media (max-width: 800px) {
        grid-column: span 6;
      }
    }

    &.image-landscape {
      grid-column: 3 / 11;

      @media (max-width: 1445px) {
        grid-column: 2 / 12;
      }

      @media (max-width: 800px) {
        grid-column: span 6;
      }
    }

    & img:not([aria-hidden="true"]) {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border: 39px solid #efefef;
      box-sizing: border-box;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }
  }
`;

export const EditorialImage = ({ editorialImage, index }) => {
  return (
    <EditorialImageContainer className="module editorial-image grid-12">
      {editorialImage.fluid !== null && (
        <div
          className={`image-container image-${ImageOrientation(
            editorialImage
          )}`}
        >
          <AspectRatioImageContainer image={editorialImage}>
            <GatsbyImage
              className={ImageOrientation(editorialImage)}
              image={editorialImage.gatsbyImageData}
              // src={editorialImage.fluid.src}
              alt={editorialImage.alt !== null ? editorialImage.alt : ``}
              fetchpriority={index <= 1 ? `high` : ``}
              loading={index <= 1 ? `eager` : `lazy`}
            />
          </AspectRatioImageContainer>

          {editorialImage.alt !== null && (
            <p className="caption uppercase">{editorialImage.alt}</p>
          )}
        </div>
      )}
    </EditorialImageContainer>
  );
};
