import React, { useContext } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";

// Context
import { CurrentLanguage } from "../context/current-language";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const CollectionsContainer = styled.div`
  padding: 50px 40px 90px 40px;

  @media (max-width: 800px) {
    padding: 31px 25px 50px 25px;
  }

  & .introduction {
    & .title-container {
      margin: 0 0 40px 0;

      @media (max-width: 1445px) {
        margin: 0 0 35px 0;
      }

      & h1 {
        font-size: 70px;
        line-height: 70px;

        @media (max-width: 999px) {
          font-size: 40px;
          line-height: 50px;
        }
      }

      @media (max-width: 800px) {
        margin: 0 0 10px 0;
      }
    }

    & .text-container {
      max-width: 1290px;
      margin: 0 0 90px 0;

      & h3 {
        @media (max-width: 800px) {
          font-size: 19px;
          line-height: 24px;
        }
      }
    }
  }

  & .content-container {
    max-width: 1280px;
    margin: 0 auto;

    @media (max-width: 999px) {
      &.grid-6 {
        grid-row-gap: 100px;
      }
    }
  }

  & .single-article {
    &:nth-of-type(4n + 1) {
      grid-column: 1 / 4;
    }

    &:nth-of-type(4n + 2) {
      grid-column: 5 / 7;
    }

    &:nth-of-type(4n + 3) {
      grid-column: 1 / 3;
    }

    &:nth-of-type(4n + 4) {
      grid-column: 4 / 7;
    }

    @media (max-width: 999px) {
      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 3) {
        grid-column: 1 / 5;
      }

      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) {
        grid-column: 4 / 7;
      }
    }

    @media (max-width: 767px) {
      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 3),
      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) {
        grid-column: 1 / 7;
      }
    }
  }
`;

const Article = styled.div`
  & .image-container {
    overflow: hidden;
    cursor: pointer;

    & img:not([aria-hidden="true"]) {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border: 39px solid #fff;
      box-sizing: border-box;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }
    }
  }

  & .title-bar {
    display: flex;
    flex-direction: row;

    & p {
      margin: 0;
    }

    & .spacer {
      margin: 0 10px;
    }
  }

  & .text-container {
    margin: 30px 0 0 0;
    padding: 0 39px;

    & .article-title {
      margin: 15px 0 20px 0;
    }

    & .article-excerpt {
      margin: 20px 0 15px 0;
    }

    @media (max-width: 999px) {
      padding: 0 27px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }
  }
`;

export const Collections = ({ content, index }) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  const items = content.items.map((item, index) => (
    <Article className="single-article">
      <div className="image-container">
        {item.collection.document !== null && (
          <Link to={item.collection.document.url}>
            {item.image.fluid !== null && (
              <AspectRatioImageContainer image={null} padding={140}>
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={item.image.alt !== null}
                  fetchpriority={index <= 1 ? `high` : ``}
                  loading={index <= 1 ? `eager` : `lazy`}
                />
              </AspectRatioImageContainer>
            )}
          </Link>
        )}
      </div>

      <div className="text-container">
        <PrismicRichText
          field={item.name.richText}
          components={{
            heading1: ({ children }) => (
              <h3 className="article-title">{children}</h3>
            ),
          }}
        />
        <PrismicRichText
          field={item.text.richText}
          components={{
            paragraph: ({ children }) => (
              <p className="article-excerpt">{children}</p>
            ),
          }}
        />

        <Link to={item.collection.document.url} className="uppercase small">
          {currentLanguage === `en` ? `See More` : `Voir Plus`}
        </Link>
      </div>
    </Article>
  ));

  return (
    <CollectionsContainer>
      <div className="introduction">
        <div className="title-container">
          <PrismicRichText field={content.primary.section_title.richText} />
        </div>

        <div className="text-container">
          <PrismicRichText
            field={content.primary.section_text.richText}
            components={{
              paragraph: ({ children }) => <h3>{children}</h3>,
            }}
          />
        </div>
      </div>

      <div className="content-container grid-6">{items}</div>
    </CollectionsContainer>
  );
};
