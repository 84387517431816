import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const ProductCardLargeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    padding: 0 !important;
    margin: 0 0 20px 0 !important;
  }

  &.landscape {
    & .single-product {
      grid-column: 3 / 11;

      @media (max-width: 1445px) {
        grid-column: 2 / 12;
        padding: 0 75px;
      }

      @media (max-width: 999px) {
        grid-column: 2 / 12;
        padding: 0;
      }

      @media (max-width: 800px) {
        grid-column: 1 / 13;
      }
    }
  }

  &.portrait {
    & .single-product {
      grid-column: 4 / 10;

      @media (max-width: 999px) {
        grid-column: 3 / 11;
        padding: 0 40px;
      }

      @media (max-width: 800px) {
        grid-column: 1 / 13;
        padding: 0;
      }
    }
  }

  & .single-product {
    & img:not([aria-hidden="true"]) {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border: 39px solid #efefef;
      box-sizing: border-box;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }

    & .text-container {
      margin: 33px 0 0 0;
      padding: 0 39px;

      @media (max-width: 999px) {
        padding: 0 27px;
      }

      @media (max-width: 800px) {
        padding: 0 25px;
        margin: 20px 0 0 0;
      }

      & h4,
      & p {
        font-size: 14px;
        line-height: 25px;

        margin: 0;
      }

      & .price {
        margin: 6px 0 0 0;

        @media (max-width: 800px) {
          margin: 0;
        }
      }
    }
  }

  & .image-container {
    & .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ProductCard = ({ content, currentLanguage }) => {
  if (content.primary.product.document === null) return null;

  if (content.primary.product.document.data !== undefined) {
    return (
      <ProductCardLargeContainer
        className={`module product-card-large ${content.primary.orientation.toLowerCase()}`}
      >
        <div className="single-product">
          <div className="image-container">
            <Link
              to={`/${currentLanguage}/products/${content.primary.product.document.data.shopify_product.handle}`}
            >
              <AspectRatioImageContainer
                image={null}
                padding={
                  content.primary.orientation.toLowerCase() === `portrait`
                    ? 133
                    : 75
                }
              >
                <GatsbyImage
                  className="product-image"
                  image={
                    content.primary.product.document.data.thumbnail
                      .gatsbyImageData
                  }
                  alt={
                    content.primary.product.document.data.thumbnail.alt !== null
                      ? content.primary.product.document.data.thumbnail.alt
                      : ``
                  }
                />
              </AspectRatioImageContainer>
            </Link>
          </div>

          <div className="text-container">
            <h4 className="title uppercase gotham-light">
              <Link
                to={`/${currentLanguage}/products/${content.primary.product.document.data.shopify_product.handle}`}
              >
                {content.primary.product.document.data.title.text}
              </Link>
            </h4>

            <p className="price gotham-light">
              {content.primary.product.document.data.price_on_demand ===
              false ? (
                <p className="price">
                  {new Intl.NumberFormat("en-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    content.primary.product.document.data.shopify_product
                      .variants[0].price
                  )}
                </p>
              ) : (
                <p className="price">
                  {currentLanguage === `en`
                    ? `Price on demand`
                    : `Prix sur demande`}
                </p>
              )}
            </p>
          </div>
        </div>
      </ProductCardLargeContainer>
    );
  } else {
    return null;
  }
};
