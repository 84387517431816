import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useWindowScroll } from "react-use";

// import { useIntersection } from "react-use";

// import { useInView } from "react-intersection-observer";
import { use100vh } from "react-div-100vh";

// Context
import { HeaderColor } from "../context/header-color";
import { PageContext } from "../context/page-context";

// Components
// import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";
import { GatsbyImage } from "gatsby-plugin-image";

const EditContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  transition: 250ms background-color ease;

  padding: 150px 50px !important;

  @media (max-width: 1445px) {
    padding: 100px 50px !important;
  }

  @media (max-width: 800px) {
    padding: 100px 0 !important;
  }

  & .image-container {
    grid-column: 2 / 7;

    @media (max-width: 1445px) {
      grid-column: 1 / 8;
      padding: 0 30px 0 0;
    }

    @media (max-width: 999px) {
      grid-column: 1 / 7;
      padding: 0 15px 0 0;
    }

    @media (max-width: 800px) {
      grid-column: 1 / 7;
      order: 1;
      padding: 0 25px;
    }

    & .aspect-ratio-container {
      position: sticky;
      top: 120px;

      max-height: calc(${(props) => props.height}px - 80px - 80px);

      @media (max-width: 800px) {
        position: relative;
        top: unset;
        max-height: unset;
      }
    }

    & img:not([aria-hidden="true"]) {
      border: 39px solid #efefef;
      box-sizing: border-box;

      width: 100%;
      max-width: fit-content;

      height: 100%;
      max-height: calc(${(props) => props.height}px - 80px - 80px);

      @media (max-width: 800px) {
        max-height: unset;
      }

      margin: 0 0 0 auto;

      /* object-fit: contain; */
      object-fit: cover;

      @media (max-width: 999px) {
        border: 27px solid #efefef;
      }
    }
  }

  & .outer-text-container {
    grid-column: 8 / 13;

    @media (max-width: 1445px) {
      padding: 0 0 0 30px;
    }

    @media (max-width: 999px) {
      grid-column: 7 / 13;
      padding: 0 0 0 15px;
    }

    @media (max-width: 800px) {
      grid-column: 1 / 7;
      order: 2;

      margin: 45px 0 0 0;
      padding: 0;
    }

    & .inner-text-container {
      max-width: 530px;

      @media (max-width: 800px) {
        max-width: 100%;
      }

      & .title {
        margin: 0 0 200px 0;

        @media (max-width: 999px) {
          margin: 0 0 100px 0;
        }

        @media (max-width: 800px) {
          padding: 0 25px;
        }
      }

      & ol {
        & li {
          margin: 0 0 70px 0;

          &:last-of-type {
            margin: 0;
          }

          & .title-bar {
            display: flex;
            flex-direction: row;

            & p {
              margin: 0;
            }

            & .spacer {
              margin: 0 10px;
            }
          }

          & .text-container {
            & .article-title {
              margin: 15px 0;
            }

            & .article-excerpt {
              margin: 15px 0;
            }
          }
        }

        @media (max-width: 800px) {
          display: flex;
          flex-direction: row;

          max-width: 100%;

          overflow-x: scroll;
          scroll-snap-type: x mandatory;

          padding: 0 25px;

          &::-webkit-scrollbar {
            display: none;
          }

          & li {
            min-width: 50vw;
            margin: 0 40px 0 0;
            scroll-snap-align: center;

            &:last-of-type {
              margin: 0;
            }
          }
        }

        @media (max-width: 500px) {
          & li {
            min-width: 70vw;
            margin: 0 25px 0 0;
          }
        }
      }
    }
  }
`;

export const Edit = ({
  editBg,
  editTitle,
  editArticles,
  currentLanguage,
  index,
}) => {
  const height = use100vh();
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [backgroundColor, setBackgroundColor] = useState(`#fff`);
  const [pageContext, setPageContext] = useContext(PageContext);

  // Window scroll
  const { x, y } = useWindowScroll();

  // Sections
  const editRef = useRef(null);

  const isInSection = () => {
    const rect = editRef.current.getBoundingClientRect();
    return rect.top + 50 <= 79 && rect.bottom >= window.innerHeight + 50;
  };

  useEffect(() => {
    setHeaderColor(isInSection() ? editBg : "#fff");
    setBackgroundColor(isInSection() ? editBg : "#fff");
  }, [y, editBg]);

  const firstArticle = editArticles
    .filter((article) => article.article.document !== null)
    .filter((article, index) => index === 0)
    .map((article) => article.article.document.data)[0];

  const articles = editArticles
    .filter((article) => article.article.document !== null)
    .map((article, index) => {
      const categories =
        article.article.document.data.categories !== undefined
          ? article.article.document.data.categories
              .filter((category) => category.category.document !== null)
              .map((category, index) => (
                <span key={`single_article_category_${index}_${article.id}`}>
                  <Link to={category.category.document.url}>
                    {category.category.document.data.title.text}
                  </Link>
                </span>
              ))
          : null;

      return (
        <li key={`single_edit_article_${index}`}>
          <div className="text-container">
            <div className="title-bar tag gotham-bold uppercase">
              {article.article.document.type !== `journal_category` && (
                <>
                  <p>
                    {article.article.document.type === `article` && (
                      <Link to={`/${currentLanguage}/journal/`}>Journal</Link>
                    )}
                    {article.article.document.type === `activity` &&
                      `Activities`}
                    {article.article.document.type === `project` && `Project`}
                  </p>
                  <p className="spacer">|</p>
                  <p>{categories}</p>
                </>
              )}
            </div>

            <PrismicRichText
              field={article.article.document.data.title.richText}
              components={{
                heading1: ({ children, index }) => (
                  <h3 className="article-title">{children}</h3>
                ),
              }}
            />
            <PrismicRichText
              field={article.article.document.data.excerpt_text.richText}
              components={{
                paragraph: ({ children, index }) => (
                  <p className="article-excerpt">{children}</p>
                ),
              }}
            />

            <div className="call-to-action">
              <Link
                to={article.article.document.url}
                className="uppercase small"
              >
                {currentLanguage === `en` ? `See More` : `Voir Plus`}
              </Link>
            </div>
          </div>
        </li>
      );
    });

  return (
    <EditContainer
      className="module edit grid-12"
      bgColor={backgroundColor}
      ref={editRef}
      height={height}
    >
      <div className="image-container">
        {firstArticle !== undefined && (
          <div className="aspect-ratio-container">
            {firstArticle.thumbnail.fluid !== null && (
              <GatsbyImage
                image={firstArticle.thumbnail.gatsbyImageData}
                alt={
                  firstArticle.thumbnail.alt !== null
                    ? firstArticle.thumbnail.alt
                    : ``
                }
                fetchpriority={index <= 1 ? `high` : ``}
                loading={index <= 1 ? `eager` : `lazy`}
              />
            )}
          </div>
        )}
      </div>

      <div className="outer-text-container">
        <div className="inner-text-container">
          <div className="title">
            <PrismicRichText field={editTitle} />
          </div>

          <ol>{articles}</ol>
        </div>
      </div>
    </EditContainer>
  );
};
